import queryString from 'query-string'
import { filter, map, mapTo, switchMap } from 'rxjs/operators'
import * as Redux from 'redux'
import * as ReduxObservable from 'redux-observable'
import * as App from '../../app'
import * as Request from '../../request'
import * as Common from '../common/constants'
import * as Actions from './actions'
import * as Constants from './constants'
import * as Types from './types'
import { getRecaptchaToken } from '../recaptcha'

const onEnter = (
  action$: ReduxObservable.ActionsObservable<Redux.Action>,
  store: Redux.Store<any>,
  {history}: any,
) => action$.ofType(App.INITIALIZING_APPLICATION).pipe(
  filter(() => history.location.pathname.startsWith(Constants.URL_HOME)),
  map(() => queryString.parse(history.location.search) as any),
  map((source: any) => Actions.setSource(source)),
)

const submitSignupWithRecaptcha = (
  action$: ReduxObservable.ActionsObservable<Redux.Action>,
  state$: any,
  {history}: any,
) => action$.ofType(Constants.SUBMIT_SIGNUP_WITH_RECAPTCHA).pipe(
  switchMap(() => getRecaptchaToken('pagesSignup', state$.value.config.recaptcha_key)),
  map((token: any) => Actions.submitSignup({
    ...state$.value.signup,
    signup: {
      ...state$.value.signup.signup,
      recaptcha: token,
    }
  })),
)

export const readyForRender = (
  action$: ReduxObservable.ActionsObservable<Redux.Action>,
  store: Redux.Store<any>,
  {history}: any,
) => action$.ofType(Request.successConstant(Common.FETCH_CONFIGURATION), Request.errorConstant(Common.FETCH_CONFIGURATION))
  .pipe(
    filter(() => history.location.pathname.startsWith(Constants.URL_HOME)),
    mapTo(App.readyForRender()),
  )

export const epic = ReduxObservable.combineEpics( onEnter, readyForRender, submitSignupWithRecaptcha )
