import queryString from 'query-string'
import * as Redux from 'redux'
import { filter, mergeMap, mapTo } from 'rxjs/operators'
import * as ReduxObservable from 'redux-observable'
import * as App from '../../app'
import * as Request from '../../request'
import * as Router from '../../router'
import * as Common from '../common'
import * as Constants from './constants'

export const onEnter = (
  action$: ReduxObservable.ActionsObservable<any>,
  store: Redux.Store<any>,
  { history }: any,
) => action$.ofType(Router.ON_ENTER).pipe(
  filter(() => history.location.pathname.startsWith(Constants.URL_HOME)),
  mergeMap((({ params }: Common.WrappedParams) => ([
    Common.fetchAllSchedules(params.community_identifier),
    Common.setGuid(queryString.parse(history.location.search).guid as string),
    Common.fetchMemberSchedule(params.community_identifier, queryString.parse(history.location.search).guid as string),
  ])))
)

export const readyForRender = (
  action$: ReduxObservable.ActionsObservable<Redux.Action>,
  store: Redux.Store<any>,
  { history }: any,
) =>
  action$.ofType(
    Request.successConstant(Common.FETCH_MEMBER_SCHEDULE),
    Request.errorConstant(Common.FETCH_MEMBER_SCHEDULE))
.pipe(
  filter(() => history.location.pathname.startsWith(Constants.URL_HOME)),
  mapTo(App.readyForRender()),
)

export const epic = ReduxObservable.combineEpics(
  onEnter,
  readyForRender
)
