import { ajax } from 'rxjs/ajax'

export const writeLog = (host: string, payload: any) => {
  if ( host ) {
    try {
      const url: string = `${host}/monitoring/dashboard/log`
      console.log("Sending log to " + url, payload)
      const headers = {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'rxjs-custom-header': 'Rxjs',
      }
      return ajax({
        body: payload,
        headers,
        method: 'POST',
        url,
      }).toPromise()
    } catch (error) {
      // do nothing. No need to print. Error would in the network
    }
  } else {
    console.log("NO URL")
  }
}
