export const FETCH_ALL_SCHEDULES = 'FETCH_ALL_SCHEDULES'

export const SUBMIT_SCHEDULE_CHANGE = 'SUBMIT_SCHEDULE_CHANGE'

export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE'

export const DAILY = 'daily'
export const WEEKLY = 'weekly'
export const BIWEEKLY = 'biweekly'
export const MONTHLY = 'monthly'

export const URL_HOME = '/preferences'