import React from 'react'
import {UnsubscribeConnected} from './component'
import * as Constants from './constants'

export const routes = [
  {
    action: () => <UnsubscribeConnected />,
    path: `${Constants.URL_HOME}/:community_identifier`,
  },
  {
    action: () => <UnsubscribeConnected />,
    path: `${Constants.URL_HOME}/${Constants.AUTO_UNSUBSCRIBE}/:community_identifier`,
  },
]
