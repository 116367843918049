import * as React from 'react'
import * as UniversalRouter from 'universal-router'
import {TopArticlesConnected} from './component'
import * as Constants from './constants'

export const routes = [
  {
    action: (context: UniversalRouter.ActionContext) => <TopArticlesConnected />,
    path: `${Constants.URL_HOME}/:community_identifier/:issue_identifier/:article_identifier`,
  },
  {
    action: (context: UniversalRouter.ActionContext) => <TopArticlesConnected />,
    path: `${Constants.URL_HOME}/:community_identifier/:issue_identifier`,
  },
  {
    action: (context: UniversalRouter.ActionContext) => <TopArticlesConnected />,
    path: `${Constants.URL_HOME}/:community_identifier`,
  },
]
