import * as Request from '../../request'
import * as CommonTypes from '../common/types'
import * as Constants from './constants'
import * as Types from './types'

export const updateSignupField = (field: CommonTypes.UpdateField) =>
  ({type: Constants.UPDATE_SIGNUP_FIELD, field})

export interface SubmitSignupPayload
  extends Request.RequestOptions { payload: Types.SignupState }
export type SubmitSignupAction = Request.RequestAction<SubmitSignupPayload>

export type SubmitSignupActionCreator = (signup: Types.SignupComponentState) => SubmitSignupAction

export const submitSignupWithRecaptcha = (signup: Types.SignupComponentState) => ({ type: Constants.SUBMIT_SIGNUP_WITH_RECAPTCHA, signup })
export const submitSignup: SubmitSignupActionCreator =
  (signupState: Types.SignupComponentState) =>
    Request.requestActionCreator<SubmitSignupPayload>(Constants.SUBMIT_SIGNUP)
    ({
      method: 'POST',
      payload: signupState.signup,
      url: `/signup/${signupState.identifier}`,
    })

export const setSource = (source: Types.SignupSourceData) =>
  ({type: Constants.SIGNUP_SOURCE, source})
