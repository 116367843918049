import React from 'react'
import * as UniversalRouter from 'universal-router'
import {SurveyLandingConnected} from './landing/component'
import {SurveyFinishedConnected} from './finished/component'
import * as Constants from './constants'

export const routes = [
  {
    action: (context: UniversalRouter.ActionContext) =>
      <SurveyFinishedConnected message={Constants.SUCCESS_USER_MESSAGE} key="survey_success" />,
    path: `${Constants.SUCCESS_URL_HOME}`,
  },
  {
    action: (context: UniversalRouter.ActionContext) =>
      <SurveyFinishedConnected message={Constants.COMPLETED_USER_MESSAGE} key="survey_completed"/>,
    path: `${Constants.COMPLETED_URL_HOME}`,
  },
  {
    action: (context: UniversalRouter.ActionContext) =>
      <SurveyLandingConnected message="" key="survey_html_content"/>,
    path: `${Constants.LANDING_URL_HOME}`,
  },
]
