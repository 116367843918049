import {INITIALIZING_APPLICATION} from '../app'
import {LOCATION_CHANGE} from './constants'
import {State} from './types'

export const reducer = (state: State = null, action: any) => {
  switch (action.type) {
    case INITIALIZING_APPLICATION:
      return action.router
    case LOCATION_CHANGE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
