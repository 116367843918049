import isNil from 'lodash/isNil'
import { filter, map, mapTo, tap } from 'rxjs/operators'
import * as ReduxObservable from 'redux-observable'
import * as App from '../../app'
import * as Request from '../../request'
import * as Router from '../../router'
import * as Common from '../common'
import * as Actions from './actions'
import * as Constants from './constants'

const onEnter: ReduxObservable.Epic = (action$, state$, {history}) =>
  action$.ofType(Router.ON_ENTER).pipe(
    filter(() => history.location.pathname.startsWith(Constants.URL_HOME)),
    filter(() => isNil(state$.value.referral.campaigns)),
    map(({params}: Router.OnEnterAction) => Actions.getCampaignProgress(params.referralCode)),
  )

const configurationsReady: ReduxObservable.Epic = (action$, state$, {history}) =>
  action$.ofType(Request.successConstant(Constants.FETCH_REFERRAL_CAMPAIGNS), Request.errorConstant(Constants.FETCH_REFERRAL_CAMPAIGNS))
    .pipe(
      filter(() => history.location.pathname.startsWith(Constants.URL_HOME)),
      filter(() => state$.value.preferences.configurations.length > 0),
      mapTo(App.readyForRender()),
    )

const campaignsReady: ReduxObservable.Epic = (action$, state$, {history}) =>
  action$.ofType(Request.successConstant(Common.FETCH_CONFIGURATION), Request.errorConstant(Common.FETCH_CONFIGURATION))
  .pipe(
    filter(() => history.location.pathname.startsWith(Constants.URL_HOME)),
    filter(() => !isNil(state$.value.referral.campaigns)),
    mapTo(App.readyForRender()),
  )

export const epic = ReduxObservable.combineEpics(onEnter, configurationsReady, campaignsReady)
