import zip from 'lodash/fp/zip'
import isEmpty from 'lodash/isEmpty'
import strictUriEncode from 'strict-uri-encode'
import * as Types from './types'

const socialMediaList: Types.Icon[] = [
  'linkedin',
  'twitter',
  'email',
]
interface ShareUrlArgs {
  name: string,
  source: string,
  text: string,
  url: string,
}
const twitterShareUrl = ({url, text, source}: ShareUrlArgs) => {
  return isEmpty(source)
    ? `https://twitter.com/intent/tweet?url=${strictUriEncode(url)}&text=${strictUriEncode(text)}`
    : `https://twitter.com/intent/tweet?url=${strictUriEncode(url)}&text=${strictUriEncode(text)}&via=${source}`
}

const linkedInShareUrl = ({url}: ShareUrlArgs) =>
  `https://www.linkedin.com/shareArticle?mini=true&url=${strictUriEncode(url)}`

  const emailShareUrl = ({url, text, name}: ShareUrlArgs) =>
  `mailto:?body=${text} at ${url}&subject=${name} Newsletter Signup`

const shareUrls = [linkedInShareUrl, twitterShareUrl, emailShareUrl]
export const socialUrls = zip(socialMediaList, shareUrls)
