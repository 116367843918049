import * as React from 'react'
import {connect} from 'react-redux'
import { Col, Container, Row} from 'reactstrap'
import * as Common from '../../common'
import * as Types from '../types'

export const SurveyFinished = ({survey, key, message}: Types.ComponentProps) =>
  <div>
    <Common.PublicHeader configurations={survey.configurations} />
    <Container>
      <Row>
        <Col>
          <header className="sur-success-page-header text-center">
            {Common.keyValue(survey.configurations, key, message)}
          </header>
        </Col>
      </Row>
    </Container>
  </div>

export const SurveyFinishedConnected = connect(
  ({survey}: Types.WrappedSurveyState) => ({survey}),
  {},
  (stateProps: Types.WrappedSurveyState, _: any, ownProps: Types.ComponentOwnProps) => ({
    ...stateProps,
    ...ownProps,
  })
)(SurveyFinished)
