import queryString from 'query-string'
import * as ReduxObservable from 'redux-observable'
import { filter, map, mapTo } from 'rxjs/operators'
import * as Request from '../../request'
import * as Router from '../../router'
import * as App from '../../app'
import * as Actions from './actions'
import * as Constants from './constants'

const fetchIcpConfigurations: ReduxObservable.Epic = (action$, state$, {history}) =>
  action$.ofType(Router.ON_ENTER)
  .pipe(
    filter(() => history.location.pathname.startsWith(Constants.URL_HOME)),
    map(({params}: any) => {
      const qs = queryString.parse(history.location.search);
      const icp_guid = params.icp_guid || params.guid || params.pid || qs.icp_guid || qs.guid || qs.pid;
      return Actions.fetchConfigurationByIcp(icp_guid);
    }),
)

const readyForRender: ReduxObservable.Epic = (action$, state$, {history}) =>
  action$.ofType(Request.successConstant(Constants.FETCH_CONFIGURATION_BY_ICP))
  .pipe(
    filter(() => history.location.pathname.startsWith(Constants.URL_HOME)),
    filter(() => state$.value.survey.configurations.length > 0 ),
    mapTo(App.readyForRender()),
  )

const getSurveyData: ReduxObservable.Epic = (action$, store, {history}) =>
  action$.ofType(Router.ON_ENTER)
  .pipe(
    filter(() => history.location.pathname.startsWith(Constants.LANDING_URL_HOME)),
    map(() => Actions.getSurveyData(history.location)),
  )

export const saveSuccessSurveyResults: ReduxObservable.Epic = (action$, store, {history}) =>
  action$.ofType(Router.ON_ENTER).pipe(
    filter(() => history.location.pathname.startsWith(Constants.SUCCESS_URL_HOME)),
    map(() => Actions.saveSurveyData(Constants.SUCCESS_SERVER_RESOURCE, history.location)),
  )

export const saveCompleteSurveyResult: ReduxObservable.Epic = (action$, store, {history}) =>
  action$.ofType(Router.ON_ENTER).pipe(
    filter(() => history.location.pathname.startsWith(Constants.COMPLETED_URL_HOME)),
    map(() => Actions.saveSurveyData(Constants.COMPLETED_SERVER_RESOURCE, history.location)),
  )

  export const epic = ReduxObservable.combineEpics(
  fetchIcpConfigurations,
  getSurveyData,
  readyForRender,
  saveCompleteSurveyResult,
  saveSuccessSurveyResults,
)
