import * as Request from '../../request'
import * as Constants from './constants'
import * as Common from '../common'

type RequestType = string

const payloadForRequestType = (rt: RequestType) =>
  rt === Constants.SUBMIT_SUBSCRIBE ? Constants.SUBSCRIBE_PAYLOAD : Constants.UNSUBSCRIBE_PAYLOAD

export interface UnsubscribePayload {
  RequestType: RequestType,
  guid: Common.Guid,
  source: string,
  notify: boolean,
  timestamp: number,
}

interface SubmitUnsubscribePayload extends Request.RequestOptions {
  payload: UnsubscribePayload
}

export type SubmitUnsubscribeAction = Request.RequestAction<SubmitUnsubscribePayload>
export type UnsubscribeActionCreator = (guid: Common.Guid, notify: boolean, source: string)
  => SubmitUnsubscribeAction

type UnsubscribeActionCreatorCreator = (actionType: RequestType) => UnsubscribeActionCreator

const genericCreator: UnsubscribeActionCreatorCreator = (requestType: RequestType) =>
  (guid: Common.Guid, notify: boolean, source: string) =>
    Request.requestActionCreator<SubmitUnsubscribePayload>(requestType)
  ({
    method: 'POST',
    payload: {
      notify,
      guid,
      RequestType: payloadForRequestType(requestType),
      source,
      timestamp: Date.now(),
    },
    url: `/unsubscribe`,
  })

export const resubscribe: UnsubscribeActionCreator = genericCreator(Constants.SUBMIT_SUBSCRIBE)
export const unsubscribe: UnsubscribeActionCreator = genericCreator(Constants.SUBMIT_UNSUBSCRIBE)
export const handleScheduleChange = (id: Common.ScheduleId) => ({ type: Common.UPDATE_SCHEDULE, id })
