import isEmpty from 'lodash/isEmpty'
import * as React from 'react'
import { connect } from 'react-redux'
import * as ReactStrap from 'reactstrap'
import * as Config from '../../config'
import * as SocialShare from '../../social-share'
import * as Common from '../common'
import * as Types from './types'
import copy from 'copy-to-clipboard'

interface Referral { referral: Types.State }
interface StateProps { url: string }
type Props = Common.WrappedPreferences & Referral & StateProps

const getShareSource = (source: string, configurations: Common.KeyValue[]): string => {
  return Common.keyValue(configurations, `${source}_handle`)
}

const getCommunityName = (configurations: Common.KeyValue[]): string => {
  return Common.keyValue(configurations, 'name')
}

const getShareText = (configurations: Common.KeyValue[]): string => {
  return Common.keyValue(configurations, 'referral_share_text') ||
         Common.keyValue(configurations, 'signup_lead_text') ||
         `Join the ${Common.keyValue(configurations, 'name', 'rasa.io powered')} newsletter.`
}

const getShareMessage = (configurations: Common.KeyValue[], referral: Types.State): string => {
  return isEmpty(referral.campaigns) ?
    Common.keyValue(configurations, 'referral_share_message_no_campaigns') || 'Share your referral link below with your network via email or social medial channels.' :
    Common.keyValue(configurations, 'referral_share_message') || 'Share your referral link below with your network via email or social medial channels, and begin earning your way to rewards.'
}

const ReferralsTextComponent = ({referral}: Referral) =>
<RenderFullWidth>
  {referral.campaigns.map((r, i) =>
    r.count > 1
    ?
      <div key={r.campaign.name+i} className="progress-container">
        <p>You have <strong>{r.count}</strong> referrals! Only <strong>{r.nextTier.min_count - r.count}</strong> more until you've reached {r.nextTier.description}.</p>
      </div>
    : r.count == 1
    ?
      <div key={r.campaign.name+i} className="progress-container">
        <p>You have 1 referral! Only <strong>{r.nextTier.min_count - r.count}</strong> more until you've reached {r.nextTier.description}.</p>
      </div>
    :
      <div key={r.campaign.name+i} className="progress-container">
        <p>Start sharing now and earn your way to rewards!</p>
      </div>
  )}
</RenderFullWidth>

const ReferralsComponent = ({preferences, referral, url}: Props) =>
  <ReactStrap.Container className="page-container">
    <RenderFullWidth background="background-grey">
      <Common.PublicHeader configurations={preferences.configurations} />
    </RenderFullWidth>
    {!isEmpty(referral.campaigns) &&
      <ReferralsTextComponent referral={referral}></ReferralsTextComponent>}
    <RenderFullWidth background="background-grey">
      <div className="share-container">
        <div className='separator'>
          <h3 className="text-uppercase">
            <span>Share</span>
          </h3>
        </div>
        <p>{getShareMessage(preferences.configurations, referral)}</p>
        <div className="referral-link">
          <ReactStrap.Input
            readOnly
            onFocus={(e) => e.currentTarget.select()}
            value={url}/>
          <ReactStrap.Button
            onClick={() => copy(url)}
            className="rasa-btn no-margin-top copy-share-url">
            COPY
          </ReactStrap.Button>
        </div>
        <div className="icons-container">
          {SocialShare.socialUrls.map(([socialName, shareUrl], i) =>
            <p key={socialName+i}>
              <SocialShare.SocialMediaLink
                socialName={socialName}
                url={shareUrl({
                  name: getCommunityName(preferences.configurations),
                  source: getShareSource(socialName, preferences.configurations),
                  url,
                  text: getShareText(preferences.configurations),
                })}/>
            </p>
          )}
        </div>
      </div>
    </RenderFullWidth>
    <RenderFullWidth>
    {referral.campaigns && referral.campaigns.map((r,i) =>
      <div key={r.campaign.name+i}className="campaign-description">
        <div className="title">{r.campaign.name}</div>
        <div dangerouslySetInnerHTML={{__html: r.campaign.description}}/>
      </div>)}
    </RenderFullWidth>
  </ReactStrap.Container>

interface RenderFullWidthProps {
  children: any,
  background?: string,
}
export const RenderFullWidth = ({ children, background = null }: RenderFullWidthProps) =>
<ReactStrap.Row>
  <ReactStrap.Col className={background}>{children}</ReactStrap.Col>
</ReactStrap.Row>

export const ReferralConnected = connect(
  ({ config, preferences, referral}: Common.WrappedPreferences & Referral & Config.WrappedState) => ({
    preferences,
    referral,
    url: `${config.base_url}/signup/${preferences.identifier}?referred_by=${referral.referralCode}`
  })
)(ReferralsComponent)
