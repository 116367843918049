import {combineEpics} from 'redux-observable'
import {epic as request} from './request'
import {epic as googleAnalytics} from './google-analytics'
import {epic as common} from './pages/common/epic'
import {epic as feedback} from './pages/feedback'
import {epic as newsbrief} from './pages/newsbrief'
import {epic as preferences} from './pages/preferences'
import {epic as referral} from './pages/referral'
import {epic as signup} from './pages/signup'
import {epic as survey} from './pages/survey/epic'
import {epic as articles} from './pages/top-articles'
import {epic as unsubscribe} from './pages/unsubscribe'

export const rootEpic = combineEpics(
  articles,
  common,
  feedback,
  googleAnalytics,
  newsbrief,
  preferences,
  referral,
  request,
  signup,
  survey,
  unsubscribe,
)
