import * as React from 'react'
import {connect} from 'react-redux'
import {Container} from 'reactstrap'
import * as Types from './types'

interface WrappedNewsbriefState {
  newsbrief: Types.NewsbriefState,
}

export const Newsbrief =
  ({newsbrief}: WrappedNewsbriefState) =>
<Container>
  <div dangerouslySetInnerHTML={{ __html: newsbrief.html }}/>
</Container>

export const NewsbriefConnected = connect(
  ({newsbrief}: WrappedNewsbriefState) => ({newsbrief}),
)(Newsbrief)
