import * as React from 'react'
import * as UniversalRouter from 'universal-router'
import { ReferralConnected } from './component'
import * as Constants from './constants'

export const routes = [
  {
    action: (context: UniversalRouter.ActionContext) => <ReferralConnected />,
    path: `${Constants.URL_HOME}/:community_identifier/:referralCode`,
  },
]
