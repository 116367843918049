
export const URL_HOME = '/survey'
export const SERVER_RESOURCE = '/survey'

export const LANDING_URL_HOME = `${URL_HOME}/landing`
export const LANDING_SERVER_RESOURCE = `${SERVER_RESOURCE}/landing`

export const SUCCESS_URL_HOME = `${URL_HOME}/success`
export const SUCCESS_SERVER_RESOURCE = `${SERVER_RESOURCE}/success`

export const COMPLETED_URL_HOME = `${URL_HOME}/completed`
export const COMPLETED_SERVER_RESOURCE = `${SERVER_RESOURCE}/completed`

export const FETCH_CONFIGURATION_BY_ICP = 'FETCH_CONFIGURATION_BY_ICP'
export const FETCH_SURVEY_DATA = 'FETCH_SURVEY_DATA'
export const SAVE_SURVEY_DATA = 'SAVE_SURVEY_DATA'

export const COMPLETED_USER_MESSAGE = "Thanks for participating in the survey!"
export const SUCCESS_USER_MESSAGE = "Thanks for your time in attempting to complete the survey."
