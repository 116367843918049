import queryString from 'query-string'
import * as ReduxObservable from 'redux-observable'
import { filter, map, withLatestFrom, mapTo } from 'rxjs/operators'
import * as App from '../../app'
import * as Request from '../../request'
import * as Router from '../../router'
import * as Common from '../common/constants'
import * as Actions from './actions'
import * as Constants from './constants'

export const onEnter: ReduxObservable.Epic = (action$, state$, {history}) =>
action$.ofType(Router.ON_ENTER).pipe(
  filter(() => history.location.pathname.startsWith(Constants.URL_HOME)),
  filter(() => !state$.value.feedback.newsbrief.token),
  map(({params}: any) =>
    Actions.fetchNewsbrief(
      queryString.parse(history.location.search).token as string,
      params.community_identifier
    )
  ),
)

export const readyForRender: ReduxObservable.Epic = (action$, store, {history}) =>
action$.ofType(
  Request.successConstant(Constants.FETCH_NEWSBRIEF),
  Request.errorConstant(Constants.FETCH_NEWSBRIEF))
.pipe(
  withLatestFrom(() => action$.ofType(Request.successConstant(Common.FETCH_CONFIGURATION), Request.errorConstant(Common.FETCH_CONFIGURATION))),
  mapTo(App.readyForRender()),
)

export const onSuccessfulSubmission: ReduxObservable.Epic = (action$, store, {history}) =>
action$.ofType(Request.successConstant(Constants.SUBMIT_NEWSBRIEF_FEEDBACK))
.pipe(
  map(() => Router.push(history.location.pathname))
)

export const epic = ReduxObservable.combineEpics(
  onEnter,
  onSuccessfulSubmission,
  readyForRender,
)
