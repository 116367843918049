import * as Request from '../../request'
import * as Constants from './constants'
import * as Types from './types'

const emptyState: Types.NewsbriefState = {
  html: null,
}

export const reducer = (state: Types.NewsbriefState = emptyState, action: Request.SuccessAction) => {
  switch (action.type) {
    case Request.successConstant(Constants.PREVIEW_NEWSBRIEF):
      return {
        html: action.payload.response.html,
      }
    default:
      return state
  }
}
