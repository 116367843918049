import {routes as unsubscribe} from './pages/unsubscribe/routes'
import {routes as newsbrief} from './pages/newsbrief/routes'
import {routes as signup} from './pages/signup/routes'
import {routes as status} from './pages/status/routes'
import {routes as feedback} from './pages/feedback/routes'
import {routes as preferences} from './pages/preferences/routes'
import {routes as referral} from './pages/referral/routes'
import {routes as survey} from './pages/survey/routes'
import {routes as topArticles} from './pages/top-articles/routes'
import {routes as notFound} from './pages/not-found/routes'

export const routes = [
  ...newsbrief,
  ...unsubscribe,
  ...signup,
  ...status,
  ...feedback,
  ...preferences,
  ...referral,
  ...survey,
  ...topArticles,
  ...notFound,
]

export default routes
