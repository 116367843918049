import * as React from 'react'
import * as Types from './types'

interface SocialMediaLinkProps {
  url: string,
  socialName: string,
}

export const SocialMediaLink = ({url, socialName}: SocialMediaLinkProps) =>
  <a href={url}
    target="_blank"
    title={socialName}
    onClick={(e: any) =>
      window.open(url, 'mywin', 'left=20,top=20,width=500,height=500,toolbar=1,resizable=0')}
    rel="noopener noreferrer">
    <img src={`https://image-api.rasa.io/image/hosted-images-newsbrief/path/social-media/${socialName}_64_color_circle.png?fit=crop&w=48&h=48`} />
  </a>
