import { isString } from 'lodash'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import * as Types from './types'
import * as Constants from './constants'

export const keyValue = (keyValues: Types.KeyValue[], key: string, defaultValue: string = ''): string => {
  const kv: Types.KeyValue = find(keyValues, (x: Types.KeyValue) => x.key === key)
  return kv ? kv.value : defaultValue
}

export const emptyState: Types.CommunityState = {
  configurations: [],
  identifier: '',
}

export const emptyScheduleState: Types.SubmittableScheduleState = {
  memberGuid: '',
  schedule: {
    isDirty: false,
    schedules: [],
    selectedScheduleId: 0,
  }
}

export const initializedState =
  (keyValues: Types.KeyValue[]): Types.SubmittableCommunityState => {
  return {
    configurations: keyValues,
    identifier: keyValue(keyValues, 'identifier', ''),
    is_submitting: false,
    submitted: false,
  }
}

export const configurationsState =
  (keyValues: Types.KeyValue[]): Types.CommunityState => {
  return {
    configurations: keyValues,
    identifier: keyValue(keyValues, 'identifier', ''),
  }
}

export const scheduleState = (responseData: Types.Schedule[], state: any) => {
  return {
    schedule: {
      ...state.schedule,
      schedules: setActiveSchedule(responseData || [], Number(state.schedule.selectedScheduleId || 0)),
    }
  }
}

export const memberScheduleState = (scheduleId: number, newUnsubscribed: boolean, state: any, isDirty: boolean = false) => {
  return {
    schedule: {
      ...state.schedule,
      isDirty,
      schedules: setActiveSchedule(state.schedule.schedules, Number(scheduleId)),
      selectedScheduleId: Number(scheduleId),
    },
    unsubscribed: isNil(newUnsubscribed) ? state.unsubscribed : newUnsubscribed,
  }
}

export const getMemberGuidFromState = (state: Types.MemberState): Types.Guid => {
  return state.memberGuid
}

export const setMemberGuidInState = (guid: string, state: Types.MemberState): Types.MemberState => {
  return {
    ...state,
    memberGuid: guid
  }
}

export const erroredState =
  (state: Types.SubmittableCommunityState, errorMessage: string = ''): Types.SubmittableCommunityState => {
  return {
    ...state,
    errored: true,
    errorMessage,
    is_submitting: false,
    submitted: false,
  }
}

export const submittedState =
  (state: Types.SubmittableCommunityState): Types.SubmittableCommunityState => {
  return {
    ...state,
    errored: false,
    is_submitting: true,
    submitted: false,
  }
}

export const returnedState =
  (state: Types.SubmittableCommunityState): Types.SubmittableCommunityState => {
  return {
    ...state,
    errored: false,
    is_submitting: false,
    submitted: true,
  }
}

export const validStringInput = (field: string): boolean => {
  return (field !== '')
}

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // tslint:disable-line
export const validEmail = (email: string): boolean => {
  return EMAIL_REGEX.test(email)
}

export const setActiveSchedule = (oldSchedules: Types.Schedule[], id: Types.ScheduleId) => {
  return oldSchedules.map((schedule: Types.Schedule) => ({
    ...schedule,
    isSelected: (schedule.id === id),
  })).filter((schedule: Types.Schedule) => (schedule.isSelected || schedule.is_active))
}

const falseValues = ['0', 'n', 'f', 'false', 'no']

export const isTruthy = (s: any): boolean => {
  if (isString(s)) {
    return falseValues.indexOf(s.toLowerCase()) === -1
  } else {
    return Boolean(s)
  }
}

export const mergeTokens = (text: string, payload: Types.CommunityPayload): string => {
  if(!text){
    return ''
  }

  return text.replace(Constants.COMMUNITY_NAME, payload.communityName || '')
}