import * as Constants from './constants'
import * as Types from './types'

export const successConstant = (prefix: Types.RequestActionPrefix) => `${prefix}_${Constants.SUCCESS}`
export const errorConstant = (prefix: Types.RequestActionPrefix) => `${prefix}_${Constants.ERROR}`
export const requestConstant = (prefix: Types.RequestActionPrefix) => `${prefix}_${Constants.REQUEST}`

export const apiUrl = (action: Types.RequestAction, state: any): string => {
  const config = state.config

  const host = config.api.origin
  if ( action ) {
    return host + action.options.url
  } else {
    return host
  }
}
