import * as React from 'react'
import {connect} from 'react-redux'
import * as ReactStrap from 'reactstrap'
import * as Redux from 'redux'
import * as CommonComponents from '../common'
import {HeaderTypes, mergeTokens} from '../common'
import * as CommonTypes from '../common/types'
import * as Utils from '../common/utils'
import * as Actions from './actions'
import * as Types from './types'
import isEmpty from 'lodash/isEmpty'

interface WrappedSignupState {
  signup: Types.SignupComponentState
}

export type submitSignupWithRecaptchaActionCreator = (signup: Types.SignupComponentState) => Redux.Action

interface RootDispatchProps {
  updateField: CommonTypes.UpdateFieldFunction,
  submitSignup: submitSignupWithRecaptchaActionCreator,
}
const communityName = (signup: Types.SignupComponentState): string => {
    const configs = signup.configurations.filter((c: CommonTypes.KeyValue) => c.key === 'name')
    return isEmpty(configs) ? signup.identifier : configs[0].value
}
const getPrivacyText = (configurations: any, communityName: string) => {
    const privacyHTML = Utils.keyValue(configurations, 'privacy_lead_text')
    return <div dangerouslySetInnerHTML={{__html: mergeTokens(privacyHTML, {communityName: communityName})}}></div>
}
export const Signup = ({signup, updateField, submitSignup}: WrappedSignupState & RootDispatchProps) => {
  switch (Utils.keyValue(signup.configurations, 'signup_banner_placement', '').toLowerCase().trim())
  {
    case "top":
      return <ReactStrap.Container>
              {(!signup.signup.iframe) && <ReactStrap.Row><AddSidebar signup={signup}></AddSidebar></ReactStrap.Row>}
              <ReactStrap.Row>
                <SignupForm signup={signup} updateField={updateField} submitSignup={submitSignup}/>
              </ReactStrap.Row>
            </ReactStrap.Container>
    case "bottom":
      return <ReactStrap.Container>
            <ReactStrap.Row>
              <SignupForm signup={signup} updateField={updateField} submitSignup={submitSignup}/>
            </ReactStrap.Row>
            {(!signup.signup.iframe) && <ReactStrap.Row><AddSidebar signup={signup}></AddSidebar></ReactStrap.Row>}
          </ReactStrap.Container>
    case "left":
      return <ReactStrap.Container>
              <ReactStrap.Row>
                {(!signup.signup.iframe) && <AddSidebar signup={signup}></AddSidebar>}
                <SignupForm signup={signup} updateField={updateField} submitSignup={submitSignup}/>
              </ReactStrap.Row>
            </ReactStrap.Container>
    default:
      return <ReactStrap.Container>
              <ReactStrap.Row>
                <SignupForm signup={signup} updateField={updateField} submitSignup={submitSignup}/>
                {(!signup.signup.iframe) && <AddSidebar signup={signup}></AddSidebar>}
              </ReactStrap.Row>
            </ReactStrap.Container>
  }
}

const AddSidebar = ({signup}: WrappedSignupState) => {
  const signupBannerPlacement = Utils.keyValue(signup.configurations, 'signup_banner_placement', '').toLowerCase().trim();
  const sidebarClassName = 'signup-sidebar-' + signupBannerPlacement;
  return <Sidebar url={Utils.keyValue(signup.configurations, 'signup_sidebar_image_url', '')}
            text={Utils.keyValue(signup.configurations, 'signup_lead_text', '') }
            signup={signup} sidebarClassName={sidebarClassName}/>
}

const SignupForm = ({signup, updateField, submitSignup}: WrappedSignupState & RootDispatchProps) =>
<ReactStrap.Col>
  {(!signup.signup.iframe) && Utils.isTruthy(Utils.keyValue(signup.configurations, 'signup_header_show', '')) &&
    <CommonComponents.PublicHeader configurations={signup.configurations} headerType={HeaderTypes.UNSUBSCRIBE} />
  }
  {signup.submitted ?
    <h1 className="thank-you">Thank you for subscribing!</h1>
  : (!signup.identifier || signup.is_submitting) ?
    <CommonComponents.BouncingBalls />
  :
    <ReactStrap.Container>
      <ReactStrap.Card>
        <ReactStrap.CardBody>
          <ReactStrap.Input
            type="email"
            valid={Utils.validEmail(signup.signup.email)}
            invalid={!Utils.validEmail(signup.signup.email)}
            placeholder="Email Address (required)"
            onChange={(e) => updateField({key: 'email', value: e.target.value})}
            value={signup.signup.email}/>
          <br/>
          <ReactStrap.Input
            valid={Utils.validStringInput(signup.signup.first_name)}
            placeholder="First Name"
            onChange={(e) => updateField({key: 'first_name', value: e.target.value})}
            value={signup.signup.first_name}/>
          <br/>
          <ReactStrap.Input
            valid={Utils.validStringInput(signup.signup.last_name)}
            placeholder="Last Name"
            onChange={(e) => updateField({key: 'last_name', value: e.target.value})}
            value={signup.signup.last_name}/>
          {signup.errored ?
          <div className='error-message'>{signup.errorMessage || 'Something went wrong. Please try again!'}</div> : null}
        </ReactStrap.CardBody>
      </ReactStrap.Card>
 <br/>
                    <ReactStrap.CardText>
                        {getPrivacyText(signup.configurations, communityName(signup))}
                    </ReactStrap.CardText>
      <ReactStrap.Container className="rasa-submit-btn">
        <ReactStrap.Button
            style={{backgroundColor: Utils.keyValue(signup.configurations, 'signup_button_color', '')}}
            disabled={!Types.validSignup(signup.signup)}
            onClick={() => submitSignup(signup)}
            className="rasa-submit-btn signup-btn"
            size="lg">
            {Utils.keyValue(signup.configurations, 'signup_button_text', '')}
        </ReactStrap.Button>
      </ReactStrap.Container>
    </ReactStrap.Container>}
</ReactStrap.Col>

const Sidebar = ({sidebarClassName, signup, text, url}:
  {sidebarClassName: string, signup: Types.SignupComponentState, text: string, url: string}) => ( url || text ?
<ReactStrap.Col md="12" lg="4" className={sidebarClassName}>
  <ReactStrap.Row>
    <ReactStrap.Col md="6" lg="12">
      <div className="sidebar-text">
        <span dangerouslySetInnerHTML={{
          __html: Utils.keyValue(signup.configurations, 'signup_lead_text', ''),
        }}>
        </span>
      </div>
    </ReactStrap.Col>
    <ReactStrap.Col md="6" lg="12">
      {url &&
        <div className="sidebar-image">
          <img src={url}/>
        </div>
      }
    </ReactStrap.Col>
  </ReactStrap.Row>
</ReactStrap.Col>
: null)

export const SignupConnected = connect(
  ({signup}: WrappedSignupState) => ({signup}),
  {
    submitSignup: Actions.submitSignupWithRecaptcha,
    updateField: Actions.updateSignupField,
  },
)(Signup)