import * as React from 'react'
import * as ReactStrap from 'reactstrap'
import * as IsActive from '../../../components/is_active'
import * as Utils from '../utils'
import * as Common from '../../common'

interface DispatchProps {
  label?: string,
  schedules: Common.Schedule[],
  onChange: Common.ScheduleChangeActionCreator,
}
export const DEFAULT_LABEL = "available schedules"

export const Schedule = ({ label = DEFAULT_LABEL, schedules, onChange }: DispatchProps) =>
  <ReactStrap.Row>
    <ReactStrap.Col>
      <header className="section-header-grey">{label}</header>
      <ReactStrap.Card>
        <ReactStrap.CardBody>
          <ReactStrap.Table striped responsive>
            <thead>
              <tr>
                <th>Selected</th>
                <th>Frequency</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {schedules.map((schedule: Common.Schedule, i: number) =>
                <tr key={`${schedule.name}-${i}`}>
                  <td>
                    <IsActive.UncontrolledRadioButton isActive={schedule.isSelected} onChange={() => onChange(schedule.id)} />
                  </td>
                  <td>{Utils.formatFrequency(schedule)}</td>
                  <td>{schedule.time}</td>
                </tr>)}
            </tbody>
          </ReactStrap.Table>
        </ReactStrap.CardBody>
      </ReactStrap.Card>
    </ReactStrap.Col>
  </ReactStrap.Row>
