import ReactGA from 'react-ga4'
import * as React from 'react'
import * as ReactDom from 'react-dom'
import * as History from 'history'
import {Provider} from 'react-redux'
import * as redux from 'redux'
import {fromEventPattern, Observable} from 'rxjs'
import routes from './routes'
import {composeWithDevTools} from 'redux-devtools-extension'
import {createEpicMiddleware} from 'redux-observable'
import UniversalRouter from 'universal-router'
import {INITIALIZING_APPLICATION} from './app'
import {rootReducer} from './rootReducer'
import {rootEpic} from './rootEpic'
import * as Router from './router'
import Raven from 'raven-js'
import createRavenMiddleware from 'raven-for-redux'
import 'babel-polyfill'

declare var __RASA_PRELOADED_STATE__: any

Raven.config(
  __RASA_PRELOADED_STATE__.config.sentry.url,
  __RASA_PRELOADED_STATE__.config.sentry.config
).install()

let options = {
  resolveRoute: Router.onEnter
}
let router = new (UniversalRouter as any)(routes, options)
const history: History.History = History.createBrowserHistory()
const routerInitialState = {
  hash: history.location.hash,
  pathname: history.location.pathname,
  search: history.location.search,
}

const history$: Observable<History.LocationDescriptorObject> = fromEventPattern(history.listen)
const dependencies = {history, history$, localStorage, window, ReactGA}
const epicMiddleware = createEpicMiddleware({dependencies})
const store: redux.Store<any> = redux.createStore(
  rootReducer,
  __RASA_PRELOADED_STATE__,
  composeWithDevTools(
    redux.applyMiddleware(
      epicMiddleware,
      createRavenMiddleware(Raven),
    )
  )
)
epicMiddleware.run(rootEpic)
const resolveRoute = (routeContext: any) => router.resolve(routeContext)
  .then((route: any) => ReactDom.hydrate(
      <Provider store={store}>
        {route}
      </Provider>,
      document.getElementById('app'),
    ),
  )
  .catch((error: Error) => store.dispatch({type: 'ERROR', error}))

store.dispatch({
  config: __RASA_PRELOADED_STATE__.config,
  router: routerInitialState,
  type: INITIALIZING_APPLICATION,
})

history$.subscribe((location: History.LocationDescriptorObject) => resolveRoute({...location, store}))
resolveRoute({...history.location, store})

