import * as Request from '../request'
import * as Common from '../pages/common/constants'
import * as Survey from '../pages/survey/constants'
import * as Router from '../router'
import * as Types from './types'
import * as Utils from './utils'

const initialOgTagState: Types.OgTagState = {
  application: '',
  description: '',
  image: '',
  siteName: '',
  title: '',
  twitterHandle: '',
  favicon: '',
  faviconLarge: '',
}

export const reducer = (state: Types.OgTagState = initialOgTagState, action: any) => {
  switch (action.type) {
    case Router.ON_ENTER:
      return {
        ...state,
        application: action.routerContext.path.split('/')[1],
      }
    case Request.successConstant(Common.FETCH_CONFIGURATION):
    case Request.successConstant(Survey.FETCH_CONFIGURATION_BY_ICP):
      return {
        ...state,
        ...Utils.ogTags(action.payload.response, state.application),
      }
    default:
      return state
  }
}
