import find from 'lodash/find'
import * as Request from '../../request'
import * as CommonTypes from '../common/types'
import * as Utils from '../common/utils'

export interface ArticleFeedbackField {
  article_id: string,
  key: string,
  value: string,
}

export interface WrappedArticleFeedbackField {
  articleField: ArticleFeedbackField
}

export interface NewsbriefArticleFeedbackState {
  message_run_detail_id: string,
  comments?: string,
  post_type?: string,
  post_url?: string,
  rating?: string,
  source?: string,
  title?: string,
  [index: string]: string,
}

export interface NewsbriefFeedbackState {
  articles?: NewsbriefArticleFeedbackState[],
  client_ip?: string,
  comments?: string,
  contact_information?: string,
  rating?: string,
  suggested_sources?: string,
  token: CommonTypes.NewsbriefToken,
}

export interface FeedbackState extends CommonTypes.SubmittableCommunityState {
  newsbrief: NewsbriefFeedbackState,
}

export interface FetchNewsbriefFeedbackPayload
  extends Request.RequestOptions { newsbriefToken: CommonTypes.NewsbriefToken }
export type FetchNewsbriefAction = Request.RequestAction<FetchNewsbriefFeedbackPayload>

export interface SubmitNewsbriefFeedbackPayload
  extends Request.RequestOptions { payload: NewsbriefFeedbackState }
export type SubmitNewsbriefFeedbackAction = Request.RequestAction<SubmitNewsbriefFeedbackPayload>

export const validFeedback = (feedback: NewsbriefFeedbackState): boolean => {
  return Utils.validStringInput(feedback.comments) ||
  Utils.validStringInput(feedback.suggested_sources) ||
         !!find(feedback.articles, (a: NewsbriefArticleFeedbackState) => (parseInt(a.rating, 10) || 0) !== 0)
}
