import * as React from 'react'
import * as ReactStrap from 'reactstrap'
import * as Constants from './constants'
import * as Types from './types'
import * as Utils from './utils'

interface WrappedConfigurations {
  configurations: Types.KeyValue[],
  headerType?: Constants.HeaderTypes
}
const headerImage = (configurations: any, headerType: Constants.HeaderTypes) => {
  switch (headerType) {
    case Constants.HeaderTypes.SIGNUP:
      return <img className="brand-header" src={getImageUrl(configurations, 'signup_header_image_url')}/>
    case Constants.HeaderTypes.UNSUBSCRIBE:
      return <img className="brand-header" src={getImageUrl(configurations, 'unsubscribe_header_image_url')}/>
    default:
      return <img className="brand-header" src={getImageUrl(configurations, 'brand_header_image_url')}/>
  }
}

const headerText = (configurations: any, headerType: Constants.HeaderTypes) => {
  if (headerType === Constants.HeaderTypes.TOP_ARTICLE) {
    return <div className="brand-header-text"
                dangerouslySetInnerHTML={{__html: Utils.keyValue(configurations, 'top_articles_header_text')}}>
    </div>
  }
  return <></>
}
const getImageUrl = (configurations: any, configurationKey: any) => {
  return Utils.keyValue(configurations, configurationKey) ? Utils.keyValue(configurations, configurationKey)
    : Utils.keyValue(configurations, 'brand_header_image_url')
}
export const PublicHeader =
  ({configurations, headerType}: WrappedConfigurations) =>
  <ReactStrap.Navbar className="rasa-public-header">
    <ReactStrap.Nav className="center">
      <ReactStrap.NavItem>
        {headerImage(configurations, headerType)}
        {headerText(configurations, headerType)}
      </ReactStrap.NavItem>
    </ReactStrap.Nav>
  </ReactStrap.Navbar>
