import React from 'react'

interface UncontrolledProps {
  isActive: boolean,
  onChange: any,
}

export const UncontrolledToggleButton = ({isActive, onChange}: UncontrolledProps) =>
 <label className="switch">
  <input type="checkbox" checked={isActive} onChange={onChange}></input>
  <span className="slider round"></span>
 </label>

export const UncontrolledRadioButton = ({isActive, onChange}: UncontrolledProps) =>
 <label className="r-radio">
  <input type="radio" checked={isActive} onChange={onChange}></input>
 </label>