import * as Request from '../../request'

export const POST_METHOD = 'POST'
export const GET_METHOD = 'GET'

export const FETCH_CONFIGURATION = 'FETCH_CONFIGURATION'

export const FETCH_MEMBER_SCHEDULE = 'FETCH_MEMBER_SCHEDULE'

export const FETCH_ALL_SCHEDULES = 'FETCH_ALL_SCHEDULES'

export const SUBMIT_SCHEDULE_CHANGE = 'SUBMIT_SCHEDULE_CHANGE'

export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE'
export const SET_GUID = 'SET_GUID'

export const PUBLIC_MEMBER_SERVER_RESOURCE = '/public/member'
export const PUBLIC_SCHEDULES_SERVER_RESOURCE = '/public/schedules'

export enum HeaderTypes {
  SIGNUP = 'signup',
  UNSUBSCRIBE = 'unsubscribe',
  TOP_ARTICLE = 'top_article'
}

export const COMMUNITY_NAME = '__COMPANY_NAME__'
