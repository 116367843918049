export const URL_HOME = '/unsubscribe'

export const AUTO_UNSUBSCRIBE = 'auto'

export const UNSUBSCRIBE_PAYLOAD = 'unsubscribe'
export const SUBSCRIBE_PAYLOAD = 'resubscribe'

export const SUBMIT_SUBSCRIBE = 'SUBMIT_SUBSCRIBE'

export const SUBMIT_UNSUBSCRIBE = 'SUBMIT_UNSUBSCRIBE'

export const FAKE_SUBMIT_SUBSCRIBE_SUCCESS = 'FAKE_SUBMIT_SUBSCRIBE_SUCCESS'
export const FAKE_SUBMIT_UNSUBSCRIBE_SUCCESS = 'FAKE_SUBMIT_UNSUBSCRIBE_SUCCESS'
