import * as Redux from 'redux'
import * as Constants from './constants'
import {RequestAction, RequestOptions} from './types'
import * as Utils from './utils'

export const requestActionCreator =
  <Options extends RequestOptions>(prefix: string) =>
  (options: Options): RequestAction<Options> =>
    ({type: Utils.requestConstant(prefix), options})

