import * as Redux from 'redux'
import * as App from './app/reducer'
import * as Config from './config'
import * as OpenGraph from './open-graph'
import * as Feedback from './pages/feedback'
import * as Newsbrief from './pages/newsbrief'
import * as Preferences from './pages/preferences'
import * as Referral from './pages/referral'
import * as Signup from './pages/signup'
import * as Survey from './pages/survey'
import * as TopArticles from './pages/top-articles'
import * as Unsubscribe from './pages/unsubscribe'
import * as Router from './router'

export const rootReducer = Redux.combineReducers({
  app: App.reducer,
  config: Config.reducer,
  newsbrief: Newsbrief.reducer,
  unsubscribeState: Unsubscribe.reducer,
  signup: Signup.reducer,
  feedback: Feedback.reducer,
  openGraph: OpenGraph.reducer,
  preferences: Preferences.reducer,
  referral: Referral.reducer,
  router: Router.reducer,
  survey: Survey.reducer,
  topArticles: TopArticles.reducer,
})
