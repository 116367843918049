import * as Redux from 'redux'
import * as ReduxObservable from 'redux-observable'
import { filter, tap, mapTo } from 'rxjs/operators'
import * as App from '../app'
import { writeLog } from '../pages/common/logging'
import * as Signup from '../pages/signup'
import * as Feedback from '../pages/feedback'
import * as TopArticles from '../pages/top-articles'
import * as Unsubscribe from '../pages/unsubscribe'
import * as Request from '../request'
import * as Router from '../router'
import * as Constants from './constants'

interface Field {
  key: string,
  value: string,
}
type Type = string
interface Api {
  key: string,
  origin: string,
}
interface Router {
  hash: string,
  pathname: string,
  search: string,
}
interface SendPageViewInterface {
  config?: Api,
  router?: Router,
  payload?: Router,
  type: Type,
}
type SendPageViewType = SendPageViewInterface
interface TokensInterface {
  admin: string,
  public: string,
}
type Tokens = TokensInterface

const onAppInitiation: ReduxObservable.Epic = ( action$, state$, {ReactGA}) =>
action$.ofType(App.INITIALIZING_APPLICATION).pipe(
  filter(() => Boolean(ReactGA)),
  tap((action: SendPageViewType) => ReactGA.initialize([
    {
      trackingId: state$.value.config.ga4_property,
    },
  ])),
  mapTo({ type: Constants.INITIATE_GOOGLE_ANALYTICS }),
)

const onLocationChange = (
  action$: ReduxObservable.ActionsObservable<Redux.Action>,
  store: Redux.Store<any>,
  {ReactGA}: any,
) => action$.ofType(Router.LOCATION_CHANGE).pipe(
  filter(() => Boolean(ReactGA)),
  tap((action: SendPageViewType) => ReactGA.send('pageview')),
  mapTo({type: Constants.GA_SEND_PAGE_VIEW}),
)

const onEnter = (
  action$: ReduxObservable.ActionsObservable<Redux.Action>,
  store: Redux.Store<any>,
  {ReactGA}: any,
) => action$.ofType(App.INITIALIZING_APPLICATION).pipe(
  filter(() => Boolean(ReactGA)),
  tap((action: SendPageViewType) => ReactGA.send('pageview')),
  mapTo({type: Constants.GA_SEND_PAGE_VIEW}),
)

const trackClickEvent = (
  action$: ReduxObservable.ActionsObservable<Redux.Action>,
  state$: any,
  {ReactGA}: any,
) => action$.ofType(
  Request.requestConstant(Feedback.SUBMIT_NEWSBRIEF_FEEDBACK),
  Request.requestConstant(Signup.SUBMIT_SIGNUP),
  Request.requestConstant(Unsubscribe.SUBMIT_SUBSCRIBE),
  Request.requestConstant(Unsubscribe.SUBMIT_UNSUBSCRIBE),
  Request.requestConstant(TopArticles.TOP_ARTICLES_SUBMIT_SIGNUP ),
).pipe(
  tap(() => console.log("STATE", state$.value)),
  tap((action: Request.RequestAction) => writeLog(Request.apiUrl(null, state$.value), {
    action: action.type,
    payload: action.options.payload,
  })),
  filter(() => Boolean(ReactGA)),
  tap((action: Request.RequestAction) => ReactGA.event(
    'terpsichore_submitting',
    { source: action.type, description: 'Public Page - Button Click' },
  )),
  mapTo({ type: Constants.GA_SEND_CLICK_EVENT }),
)

const trackResponseSuccess = (
  action$: ReduxObservable.ActionsObservable<Redux.Action>,
  store: Redux.Store<any>,
  {ReactGA}: any,
) => action$.ofType(
  Request.successConstant(Feedback.SUBMIT_NEWSBRIEF_FEEDBACK),
  Request.successConstant(Signup.SUBMIT_SIGNUP),
  Request.successConstant(Unsubscribe.SUBMIT_SUBSCRIBE),
).pipe(
  filter(() => Boolean(ReactGA)),
  tap((action: Redux.Action) => ReactGA.event(
    'terpsichore_submitted',
    { source: action.type, description: 'Public Page - Submitted' },
  )),
  mapTo({ type: Constants.GA_SEND_CLICK_EVENT }),
)

const trackResponseError = (
  action$: ReduxObservable.ActionsObservable<Redux.Action>,
  store: Redux.Store<any>,
  {ReactGA}: any,
) => action$.ofType(
  Request.errorConstant(Feedback.SUBMIT_NEWSBRIEF_FEEDBACK),
  Request.errorConstant(Signup.SUBMIT_SIGNUP),
  Request.errorConstant(Unsubscribe.SUBMIT_UNSUBSCRIBE),
).pipe(
  filter(() => Boolean(ReactGA)),
  tap((action: Redux.Action) => ReactGA.event(
    'terpsichore_failed',
    { source: action.type, description: 'Public Page - Failed' },
  )),
  mapTo({ type: Constants.GA_SEND_CLICK_EVENT }),
)

const trackInputEvent = (
  action$: ReduxObservable.ActionsObservable<Redux.Action>,
  store: Redux.Store<any>,
  {ReactGA}: any,
) => action$.ofType(
  Feedback.UPDATE_ARTICLE_FEEDBACK_FIELD,
  Feedback.UPDATE_FEEDBACK_FIELD,
  Signup.UPDATE_SIGNUP_FIELD,
).pipe(
  filter(() => Boolean(ReactGA)),
  tap((action: Redux.Action) => ReactGA.event(
    'terpsicore_updating',
    { source: action.type, description: 'Public Page - Button Click' },
  )),
  mapTo({ type: Constants.GA_SEND_USER_INPUT }),
)

export const epic = ReduxObservable.combineEpics(
  onAppInitiation,
  onLocationChange,
  onEnter,
  trackClickEvent,
  trackResponseError,
  trackResponseSuccess,
  trackInputEvent,
)
