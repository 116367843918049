import * as Request from '../../request'
import * as Common from '../common'
import * as Constants from './constants'

export const initialState: Common.ScheduleComponentState = {
  ...Common.emptyState,
  ...Common.emptyScheduleState,
}
type Action = Request.SuccessAction & Common.SetGuidAction & Common.ScheduleChangeAction

export const reducer = (state: Common.ScheduleComponentState = initialState, action: Action) => {
  switch (action.type) {
    case Request.successConstant(Constants.FETCH_ALL_SCHEDULES):
      return {
        ...state,
        ...Common.scheduleState(action.payload.response, state),
      }
    case Request.successConstant(Common.FETCH_CONFIGURATION):
      return {
        ...state,
        ...Common.initializedState(action.payload.response),
      }
    case Request.successConstant(Common.FETCH_MEMBER_SCHEDULE):
      const results = action.payload.response.results
      const scheduleId = results.length > 0 ? results[0].data.schedule_id : 0
      const unsubscribed = results.length > 0 ? !results[0].data.subscribed : false
      return {
        ...state,
        ...Common.memberScheduleState(scheduleId, unsubscribed, state),
      }
    case Constants.UPDATE_SCHEDULE:
      return {
        ...state,
        ...Common.memberScheduleState(action.id, null, state, true),
      }
    case Common.SET_GUID:
      return {
        ...state,
        ...Common.setMemberGuidInState(action.guid, state)
      }
    case Request.requestConstant(Constants.SUBMIT_SCHEDULE_CHANGE):
      return Common.submittedState(state)
    case Request.successConstant(Constants.SUBMIT_SCHEDULE_CHANGE):
    case Request.errorConstant(Constants.SUBMIT_SCHEDULE_CHANGE):
      return {
        ...state,
        ...Common.returnedState(state),
        schedule: {
          ...state.schedule,
          isDirty: false,
        }
      }
    default:
      return state
  }
}
