import * as Types from './types'

export const URL_HOME = '/signup'

export const SUBMIT_SIGNUP = 'SUBMIT_SIGNUP'
export const SUBMIT_SIGNUP_WITH_RECAPTCHA = 'SUBMIT_SIGNUP_WITH_RECAPTCHA'

export const UPDATE_SIGNUP_FIELD = 'UPDATE_SIGNUP_FIELD'
export const SIGNUP_SOURCE = 'SIGNUP_SOURCE'
export const SIGNUP_CAMPAIGN = 'SIGNUP_CAMPAIGN'
export const REFERRED_BY = 'REFERRED_BY'

export const DEFAULT_SOURCE: Types.SignupSource = 'signup-page'
export const DEFAULT_CAMPAIGN: Types.SignupCampaign = null
