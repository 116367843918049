import React from 'react'
import * as UniversalRouter from 'universal-router'
import {NewsbriefFeedbackConnected} from './component'
import * as Constants from './constants'

export const routes = [
  {
    action: (context: UniversalRouter.ActionContext) => <NewsbriefFeedbackConnected/>,
    path: `${Constants.URL_HOME}/:community_identifier`,
  },
]
