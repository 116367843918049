import * as Request from '../../request'
import * as CommonConstants from '../common/constants'
import * as CommonTypes from '../common/types'
import * as Utils from '../common/utils'
import * as Constants from './constants'
import * as Types from './types'

const emptyState: Types.SignupComponentState = {
  ...Utils.emptyState,
  signup: {
    utm_campaign: Constants.DEFAULT_CAMPAIGN,
    referred_by: null,
    email: '',
    first_name: '',
    last_name: '',
    utm_source: Constants.DEFAULT_SOURCE,
  },
}

type Action = Request.SuccessAction & Request.ErrorAction & CommonTypes.WrappedUpdateField & Types.SignupSourceState

export const reducer =
  (state: Types.SignupComponentState = emptyState, action: Action) => {
  switch (action.type) {
    case Request.successConstant(CommonConstants.FETCH_CONFIGURATION):
      return {
        ...Utils.initializedState(action.payload.response),
        signup: {
          ...state.signup,
          email: '',
          first_name: '',
          last_name: '',
        },
      }
    case Constants.UPDATE_SIGNUP_FIELD:
      return {
          ...state,
          signup: {
            ...state.signup,
            ...{[action.field.key]: action.field.value},
          },
        }
    case Constants.SIGNUP_SOURCE:
      return {
        ...state,
        signup: {
          ...state.signup,
          iframe: action.source.iframe || null,
          utm_campaign: action.source.utm_campaign || Constants.DEFAULT_CAMPAIGN,
          utm_source: action.source.utm_source || Constants.DEFAULT_SOURCE,
          referred_by: action.source.referred_by || null,
        },
      }
    case Request.requestConstant(Constants.SUBMIT_SIGNUP):
      return Utils.submittedState(state)
    case Request.successConstant(Constants.SUBMIT_SIGNUP):
      return Utils.returnedState(state)
    case Request.errorConstant(Constants.SUBMIT_SIGNUP): {
      console.log('error', action)
      return Utils.erroredState(state, action.payload.error && action.payload.error.response && 
          action.payload.error.response.message ? action.payload.error.response.message : '')
    }
    default:
      return state
  }
}
