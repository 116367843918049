import * as Common from '../common'
import * as Constants from './constants'

export const formatFrequency = (s: Common.Schedule): string => {
  switch (s.frequency) {
    case Constants.DAILY:
      return 'Every Day'
    case Constants.WEEKLY:
      return weekDays(s.days)
    case Constants.BIWEEKLY:
      if (s.weeks === '1,2') {
        return weekDays(s.days)
      } else {
        return weekDays(s.days) + '; Every other week.'
      }
    case Constants.MONTHLY:
      if (s.weeks === '1,2,3,4,5') {
        return weekDays(s.days)
      } else {
        return weekDays(s.days) + '; ' + weekNumbers(s.weeks) + ' weeks of the month.'
      }
    default:
      return s.frequency + ' Weeks: ' + s.weeks + ', ' + weekDays(s.days)
  }
}

const weekDays = (weekDayString: string): string => {
  switch (weekDayString) {
    case 'MTWRFSU': return 'Every Day'
    case 'MTWRF': return 'Weekdays'
    default: return joinList(weekDayString.split('').map((s: string) => weekDayName(s)))
  }
}

const weekNumbers = (weekNumberString: string): string => {
  const weeks = weekNumberString.split(',').map((s: string) => ordinalNumber(s))
  return joinList(weeks)
}

const joinList = (strings: string[]): string => {
  if (strings.length > 1) {
    const last = strings.pop()
    return strings.join(', ') + ' and ' + last
  } else {
    return strings.join('')
  }
}

const ordinalNumber = (weekNumberString: string): string => {
  const weekNumber = +weekNumberString
  switch (weekNumber % 10) {
    case 1: return weekNumber + 'st'
    case 2: return weekNumber + 'nd'
    case 3: return weekNumber + 'rd'
    default: return weekNumber + 'th'
  }
}

interface StringMap {
  [key: string]: string,
}
const WEEKDAYS: StringMap = {
  F: 'Friday',
  M: 'Monday',
  R: 'Thursday',
  S: 'Saturday',
  T: 'Tuesday',
  U: 'Sunday',
  W: 'Wednesday',
}

const weekDayName = (weekDayChar: string): string => WEEKDAYS[weekDayChar]