import * as redux from 'redux'
import * as ReduxObservable from 'redux-observable'
import {Observable, forkJoin} from 'rxjs'
import {catchError, map, mapTo, tap, filter, mergeMap} from 'rxjs/operators'
import { ajax } from 'rxjs/ajax';
import  * as Constants from './constants'
import {ErrorAction, RequestAction, SuccessAction} from './types'
import  * as Utils from './utils'

let {ERROR, REQUEST, SUCCESS} = Constants

const XHR2 = typeof XMLHttpRequest !== 'undefined'
  ? XMLHttpRequest
  : require('xhr2');

export const request = (options: any) => ajax({createXHR: () => new XHR2(), ...options })

export const concurrentEpic: ReduxObservable.Epic = (action$, state$, {window}: any) => action$.pipe(
  filter((action) => action.type.includes(REQUEST)),
  mergeMap((action: RequestAction) =>
    request({
      body: action.options.payload,
      headers: {
        'Content-Type': 'application/json',
      },
      method: action.options.method,
      responseType: 'json',
      url: Utils.apiUrl(action, state$.value),
    }).pipe(
      map((payload: any): SuccessAction => {
        console.log(new Date(), "Successful response from " + state$.value.config.api.origin + action.options.url)
        return ({type: action.type.replace(REQUEST, SUCCESS), payload, requestAction: action})
      }),
      catchError((error: any): ErrorAction[] => {
        if (error.status === 404 && window && window.location) {
          window.location.href = '/not-found'
        }
        console.log(new Date(), "Error from " + state$.value.config.api.origin + action.options.url, error)
        return [({type: action.type.replace(REQUEST, ERROR), payload: {error}, requestAction: action})]
      })
    )
  )
)

export const epic = ReduxObservable.combineEpics( concurrentEpic )
