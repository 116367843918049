import * as History from 'history'
import * as redux from 'redux'
import {GO, GO_BACK, GO_FORWARD, LOCATION_CHANGE, PUSH, REDIRECT, REPLACE} from './constants'

export interface PushAction extends redux.Action {payload: string}
export interface ReplaceAction extends redux.Action {payload: string}
export interface RedirectAction extends redux.Action {payload: string}
export interface GoAction extends redux.Action {payload: number}
export type LocationChangePayload = History.LocationDescriptorObject
export interface LocationChange extends redux.Action {payload: LocationChangePayload}

export const push = (href: string): PushAction => ({type: PUSH, payload: href})
export const replace = (href: string): ReplaceAction => ({type: REPLACE, payload: href})
export const redirect = (href: string): RedirectAction => ({type: REDIRECT, payload: href})
export const go = (index: number): GoAction => ({type: GO, payload: index})
export const goBack = (): redux.Action => ({type: GO_BACK})
export const goForward = (): redux.Action => ({type: GO_FORWARD})
export const locationChange = (location: LocationChangePayload): LocationChange =>
  ({type: LOCATION_CHANGE, payload: location})
