import * as App from '../app'
export interface ApiState {
  origin: string,
}
export interface Sentry {
  url: string
  config: {}
}
export interface State {
  api: ApiState,
  base_url: string,
  ga4_property: string,
  recaptcha_key: string,
  sentry: Sentry,
}
export interface WrappedState {
  config: State
}

const initialState = {
  api: {
    origin: '',
  },
  base_url: 'https://pages.rasa.io',
  ga4_property: '',
  recaptcha_key: '',
  sentry: {
    url: '',
    config: {}
  }
}
export const reducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case App.INITIALIZING_APPLICATION:
      return action.config
    default:
      return state
  }
}
