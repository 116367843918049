import Raven from 'raven-js'
import * as ReduxObservable from 'redux-observable'
import {delay, filter, map, mapTo, tap} from 'rxjs/operators'
import * as Request from '../../request'
import * as Router from '../../router'
import * as App from '../../app'
import * as Feedback from '../feedback/constants'
import * as Newsbrief from '../newsbrief/constants'
import * as Preferences from '../preferences/constants'
import * as Referral from '../referral/constants'
import * as Signup from '../signup/constants'
import * as Status from '../status/constants'
import * as TopArticles from '../top-articles/constants'
import * as Unsubscribe from '../unsubscribe/constants'
import * as Actions from './actions'
import * as Constants from './constants'

export const fetchConfigurations: ReduxObservable.Epic = (action$, state$, {history}) =>
action$.ofType(Router.ON_ENTER)
.pipe(
  filter(() => history.location.pathname.startsWith(Signup.URL_HOME) ||
                history.location.pathname.startsWith(Feedback.URL_HOME) ||
                history.location.pathname.startsWith(Newsbrief.URL_HOME) ||
                history.location.pathname.startsWith(Preferences.URL_HOME) ||
                history.location.pathname.startsWith(TopArticles.URL_HOME) ||
                history.location.pathname.startsWith(Unsubscribe.URL_HOME) ||
                history.location.pathname.startsWith(Referral.URL_HOME)),
  map(({params}: any) => Actions.fetchConfiguration(params.community_identifier)),
)

export const renderAfterConfiguration: ReduxObservable.Epic = (action$) =>
  action$.ofType(Request.successConstant(Constants.FETCH_CONFIGURATION))
  .pipe(
    mapTo(App.readyForRender()),
  )

export const renderStatus: ReduxObservable.Epic = (action$, state$, {history}) =>
action$.ofType(Router.ON_ENTER)
.pipe(
  filter(() => history.location.pathname.startsWith(Status.URL_HOME)),
  mapTo(App.readyForRender()),
)

export const timeout: ReduxObservable.Epic = (action$, state$, {history}) =>
action$.pipe(
    delay(1000 * 10),
    filter(() => !state$.value.app.readyForRender),
    tap(() => console.log(`Timeout Reached! ${JSON.stringify(history)}`)),
    map(() => Raven.captureException(new Error('Timeout Reached!'))),
    mapTo(App.readyForRender()),
  )

export const epic = ReduxObservable.combineEpics(
  fetchConfigurations,
  renderAfterConfiguration,
  renderStatus,
  timeout
)
