import * as Request from '../../request'
import * as Constants from './constants'
import * as Types from './types'

type FetchConfigurationByIcpActionCreator =
  (icp_guid: string) =>
  Request.RequestAction<Request.RequestOptions>

export const fetchConfigurationByIcp: FetchConfigurationByIcpActionCreator =
  (icp_guid: string) =>
    Request.requestActionCreator<Request.RequestOptions>(Constants.FETCH_CONFIGURATION_BY_ICP)
    ({url: `/community/icp/${icp_guid}/config/public`, method: 'GET'})

export const getSurveyData = (payload: any) => {
  return Request.requestActionCreator(Constants.FETCH_SURVEY_DATA)
  ({
    method: 'GET',
    payload: payload,
    url: `${Constants.LANDING_SERVER_RESOURCE}${payload.search}`,
  })
}

interface SubmitDataPayload extends Request.RequestOptions {
  payload: Types.Survey,
}
export type SubmitSurveyDataAction = Request.RequestAction<SubmitDataPayload>

export type SurveySubmitDataActionCreator = (endpoint: string, survey: Types.Survey) => SubmitSurveyDataAction

export const saveSurveyData: SurveySubmitDataActionCreator = (endpoint: string, survey: Types.Survey) =>
  Request.requestActionCreator<SubmitDataPayload>(Constants.SAVE_SURVEY_DATA)
  ({
    method: 'POST',
    payload: survey,
    url: endpoint,
  })
