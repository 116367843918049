import * as Request from '../../request'
import * as CommonTypes from '../common/types'
import * as Constants from './constants'
import * as Types from './types'

export const updateFeedbackField = (field: CommonTypes.UpdateField) =>
  ({type: Constants.UPDATE_FEEDBACK_FIELD, field})
export const updateArticleFeedbackField = (articleField: Types.ArticleFeedbackField) =>
  ({type: Constants.UPDATE_ARTICLE_FEEDBACK_FIELD, articleField})

type FetchNewsbriefActionCreator =
  (newsbriefToken: CommonTypes.NewsbriefToken, communityIdentifier: CommonTypes.CommunityIdentifier) =>
    Types.FetchNewsbriefAction

export const fetchNewsbrief: FetchNewsbriefActionCreator =
  (newsbriefToken: CommonTypes.NewsbriefToken, communityIdentifier: CommonTypes.CommunityIdentifier) =>
    Request.requestActionCreator<Types.FetchNewsbriefFeedbackPayload>(Constants.FETCH_NEWSBRIEF)
    ({
      method: 'GET',
      newsbriefToken,
      url: `/newsbrief/${communityIdentifier}/${newsbriefToken}`,
    })

export type SubmitNewsbriefFeedbackActionCreator =
  (newsbriefFeedback: Types.NewsbriefFeedbackState, communityIdentifier: CommonTypes.CommunityIdentifier) =>
    Types.SubmitNewsbriefFeedbackAction

export const submitFeedback: SubmitNewsbriefFeedbackActionCreator =
  (newsbriefFeedback: Types.NewsbriefFeedbackState, communityIdentifier: CommonTypes.CommunityIdentifier) =>
    Request.requestActionCreator<Types.SubmitNewsbriefFeedbackPayload>(Constants.SUBMIT_NEWSBRIEF_FEEDBACK)
    ({
      method: 'PUT',
      payload: newsbriefFeedback,
      url: `/newsbrief/${communityIdentifier}/${newsbriefFeedback.token}`,
    })
