import * as React from 'react'
import {connect} from 'react-redux'
import * as ReactStrap from 'reactstrap'
import * as Common from '../common'
import {Schedule} from '../preferences/schedule/component'
import * as Actions from './actions'

interface DispatchProps {
  submitUpdateSchedule: Common.UpdateScheduleActionCreator,
  onChange: Common.ScheduleChangeActionCreator,
}

type Props = DispatchProps & Common.WrappedPreferences

const Preferences =
({ onChange, preferences, submitUpdateSchedule }: Props) =>
  <ReactStrap.Container>
    <ReactStrap.Row>
      <ReactStrap.Col>
        <Common.PublicHeader configurations={preferences.configurations} />
        <header className="page-header text-center">Email Preferences</header>
      </ReactStrap.Col>
    </ReactStrap.Row>
    <ReactStrap.Row>
      <ReactStrap.Col sm="12">
        <div className="lead-text centered">
          <span dangerouslySetInnerHTML={{
            __html: Common.keyValue(preferences.configurations, 'preferences_lead_text', ''),
          }}>
          </span>
        </div>
      </ReactStrap.Col>
    </ReactStrap.Row>
    <ReactStrap.Container>
      <Schedule schedules={preferences.schedule.schedules} onChange={onChange} />
    </ReactStrap.Container>
    <ReactStrap.Container className="rasa-submit-btn">
      <ReactStrap.Row>
        <ReactStrap.Col>
          {preferences.is_submitting ?
            <Common.BouncingBalls />
          :
            <ReactStrap.Button
              style={{ backgroundColor: Common.keyValue(preferences.configurations, 'primary_color', '') }}
              disabled={!preferences.schedule.isDirty}
              onClick={() =>
                submitUpdateSchedule(
                  preferences.identifier,
                  preferences.schedule.selectedScheduleId,
                  Common.getMemberGuidFromState(preferences))}
              className="rasa-submit-btn">
              Save Preferences
            </ReactStrap.Button>}
        </ReactStrap.Col>
      </ReactStrap.Row>
    </ReactStrap.Container>
  </ReactStrap.Container>

export const PreferencesConnected = connect(
  ({ preferences }: Common.WrappedPreferences) => ({ preferences }),
  {
    onChange: Actions.handleScheduleChange,
    submitUpdateSchedule: Common.updateMemberSchedule,
  }
)(Preferences)
