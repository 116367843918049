import * as Redux from 'redux'
import * as router from 'universal-router'
import * as Constants from './constants'



import {Observable} from 'rxjs'

interface RasaRouterContext extends router.Context {
  store$: any
  store: Redux.Store<any>
  route: { action: () => any } // any is appropriate in this case
}
export const onEnter = (routerContext: any, params: router.Params) => {
  let {store, path, route} = routerContext
  if (path) {
    store.dispatch({type: Constants.ON_ENTER, routerContext, params})
    return route.action()
  }
}
