import * as React from 'react'
import {connect} from 'react-redux'

export const NotFound = ({}) => {
  return(
    <div className='not-found'>
      <h3>404 page not found</h3>
      <p>We are sorry but the page you are looking for does not exist.</p>
    </div>
  )}

export const NotFoundConnected = connect(({}: any) => ({}),{})(NotFound)
