import * as History from 'history'
import * as redux from 'redux'
import * as reduxObservable from 'redux-observable'
import {Observable} from 'rxjs'
import { tap, withLatestFrom, mapTo } from 'rxjs/operators';
import {locationChange, PushAction, ReplaceAction} from './actions'
import {GO_BACK, PUSH, REDIRECT, REPLACE} from './constants'

interface Dependencies {history: History.History, history$: Observable<History.LocationDescriptorObject>}

export const pushEpic = (
  action$: reduxObservable.ActionsObservable<PushAction>,
  store: redux.Store<any>,
  {history, history$}: Dependencies,
) => action$.ofType(PUSH).pipe(
  tap((action: PushAction) => history.push(action.payload)),
  withLatestFrom(history$, (action: PushAction, location: History.LocationDescriptorObject) =>
    locationChange({
      hash: location.hash,
      pathname: location.pathname,
      search: location.search,
    }),
  ),
)

export const replaceEpic = (
  action$: reduxObservable.ActionsObservable<ReplaceAction>,
  store: redux.Store<any>,
  {history, history$}: Dependencies,
) => action$.ofType(REPLACE).pipe(
  tap((action: ReplaceAction) => history.replace(action.payload)),
  withLatestFrom(history$, (action: PushAction, location: History.LocationDescriptorObject) =>
    locationChange({
      hash: location.hash,
      pathname: location.pathname,
      search: location.search,
    })),
)

export const redirectEpic = (
  action$: reduxObservable.ActionsObservable<ReplaceAction>,
  store: redux.Store<any>,
  {window}: any,
) => action$.ofType(REDIRECT).pipe(
  tap((action: any) => window.location.href = action.payload),
  mapTo({type: 'REDIRECTED'}),
)

export const goBackEpic = (
  action$: reduxObservable.ActionsObservable<redux.Action>,
  store: redux.Store<any>,
  {history, history$}: Dependencies,
) => action$.ofType(GO_BACK).pipe(
  tap(() => history.goBack()),
  withLatestFrom(history$, (action: PushAction, location: History.LocationDescriptorObject) =>
    locationChange({
      hash: location.hash,
      pathname: location.pathname,
      search: location.search,
    }),
  )
)

export const epic = reduxObservable.combineEpics(
  goBackEpic,
  pushEpic,
  redirectEpic,
  replaceEpic,
)
