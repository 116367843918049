import * as Request from '../../request'
import * as Constants from './constants'
import * as Types from './types'

type FetchConfigurationActionCreator =
  (communityIdentifier: Types.CommunityIdentifier) =>
  Request.RequestAction<Request.RequestOptions>

export const fetchConfiguration: FetchConfigurationActionCreator =
  (communityIdentifier: Types.CommunityIdentifier) =>
    Request.requestActionCreator<Request.RequestOptions>(Constants.FETCH_CONFIGURATION)
    ({url: `/community/${communityIdentifier}/config/public`, method: 'GET'})

type FetchRequestActionCreator =
  (communityIdentifier: Types.CommunityIdentifier, guid?: Types.Guid) =>
    Request.RequestAction<Request.RequestOptions>

export const fetchAllSchedules: FetchRequestActionCreator =
  (communityIdentifier: Types.CommunityIdentifier) =>
    Request.requestActionCreator(Constants.FETCH_ALL_SCHEDULES)
      ({
        url: `${Constants.PUBLIC_SCHEDULES_SERVER_RESOURCE}/${communityIdentifier}?is_active=true`,
        method: 'GET',
       })

export const fetchMemberSchedule: FetchRequestActionCreator =
  (communityIdentifier: Types.CommunityIdentifier, guid: Types.Guid) =>
    Request.requestActionCreator(Constants.FETCH_MEMBER_SCHEDULE)
      ({
        method: 'GET',
        url: `${Constants.PUBLIC_MEMBER_SERVER_RESOURCE}/${communityIdentifier}/${guid}`
      })

export type UpdateScheduleActionCreator =
  (communityIdentifier: Types.CommunityIdentifier, scheduleId: Types.ScheduleId, guid: Types.Guid) =>
    Types.UpdateScheduleAction

export const updateMemberSchedule: UpdateScheduleActionCreator =
  (communityIdentifier: Types.CommunityIdentifier, scheduleId: Types.ScheduleId, guid: Types.Guid) =>
    Request.requestActionCreator<Types.UpdateSchedulePayload>(Constants.SUBMIT_SCHEDULE_CHANGE)
      ({
        method: 'PUT',
        payload: {
          notify: true,
          schedule_id: scheduleId,
        },
        url: `${Constants.PUBLIC_MEMBER_SERVER_RESOURCE}/${communityIdentifier}/${guid}`,
      })

export const setGuid = (guid: Types.Guid) => ({ type: Constants.SET_GUID, guid })