
export const withRecaptcha = (action: any, key: string, handler: any) => {
  if ( key ) {
    const recaptcha: any = (window as any).grecaptcha.enterprise
    recaptcha.ready(() => {
      recaptcha.execute(key, {action})
        .then((token: any) => handler(token))
    })
  } else {
    handler(null)
  }
}

export const getRecaptchaToken = (action: any, key: string) => {
  if ( key && (window as any).grecaptcha ) {
    const recaptcha: any = (window as any).grecaptcha.enterprise
    return new Promise((resolve) => {
      recaptcha.ready(() => {
        recaptcha.execute(key, {action})
          .then((token: any) => resolve(token))
      })
    })
  } else {
    return Promise.resolve(null)
  }
}
