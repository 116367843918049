import * as Request from '../../request'
import * as Constants from './constants'

export const getCampaignProgress = (referralCode: string) =>
  Request.requestActionCreator(Constants.FETCH_REFERRAL_CAMPAIGNS)
  ({
    method: 'GET',
    payload: referralCode,
    url: `/campaigns/${referralCode}`,
  })
