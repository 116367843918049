import React from 'react'

const BallYellow = () => <svg xmlns="http://www.w3.org/2000/svg" className="ball" viewBox="0 0 10.584 11.33">
  <path d="M5.203 0a5.323 5.323 0 011.195.239c.387.093.679.476 1.028.654s.885.1 1.189.355.356.817.6 1.141.773.485.943.86a6.288 6.288 0 01.414 1.229c.085.4-.363.873-.363 1.3a5.536 5.536 0 01-.052 1.2 5.369 5.369 0 01-.383 1.132 5.272 5.272 0 01-.6 1.023c-.244.324-.329.818-.635 1.077a5.954 5.954 0 01-.934.854 5.655 5.655 0 01-1.217.222 5.2 5.2 0 01-1.188.036 4.9 4.9 0 01-1.148-.22 5.3 5.3 0 01-1.178-.211c-.355-.181-.542-.644-.846-.9a5.142 5.142 0 01-.732-.915c-.242-.322-.761-.454-.932-.829s.089-.887 0-1.3-.367-.744-.367-1.168a5.738 5.738 0 01.132-1.224 5.813 5.813 0 01.314-1.2 5.589 5.589 0 01.661-1.031 5.319 5.319 0 01.931-.744 5.2 5.2 0 01.9-.774 4.942 4.942 0 011.11-.374C4.424.338 4.803 0 5.203 0z" fill="#f7e38c"/>
</svg>

const BallRed = () => <svg xmlns="http://www.w3.org/2000/svg" className="ball" viewBox="0 0 10.695 10.976">
  <path d="M5.381.06a4.834 4.834 0 011.116.3 4.948 4.948 0 011.13.232 5.068 5.068 0 01.924.717c.307.255.846.281 1.091.6s.13.88.3 1.251a5.221 5.221 0 01.443 1.1 2.691 2.691 0 01.059 2.393c-.088.4.057.915-.111 1.28a5.893 5.893 0 01-.8.964 5.41 5.41 0 01-.885.818 5.264 5.264 0 01-.974.693 5.366 5.366 0 01-1.085.553c-.375.089-.807-.173-1.208-.173a5.126 5.126 0 01-1.169-.008 5.265 5.265 0 01-1.16-.3 5.491 5.491 0 01-1.034-.646 4.247 4.247 0 01-1.224-2.09 5.345 5.345 0 01-.48-1.095 5.6 5.6 0 01-.182-1.2 6.59 6.59 0 01-.111-1.273A6.039 6.039 0 01.48 2.993a5.819 5.819 0 01.693-1.036 5.7 5.7 0 01.84-.9c.305-.254.868-.06 1.223-.239s.569-.687.957-.779A5.337 5.337 0 015.381.06z" fill="#de7b74"/>
</svg>

const BallCyan = () => <svg xmlns="http://www.w3.org/2000/svg" className="ball" viewBox="0 0 10.572 10.616">
  <path d="M5.106.003a4.988 4.988 0 011.13.27c.387.09.9-.158 1.249.014s.583.608.888.856.38.7.625 1.01.924.355 1.095.716a6.228 6.228 0 01.151 1.25 5.763 5.763 0 01.328 1.194c0 .407-.432.769-.516 1.15a22 22 0 01-.981 2.072 5.5 5.5 0 01-.676.981 5.58 5.58 0 01-.943.762 5.472 5.472 0 01-1.17.3 5.355 5.355 0 01-1.18-.006 4.883 4.883 0 01-1.119-.269 4.72 4.72 0 01-1.063-.376 4.893 4.893 0 01-.993-.56 5.016 5.016 0 01-.734-.88c-.243-.311-.674-.482-.844-.844A5.517 5.517 0 01.06 6.486a5.366 5.366 0 01-.053-1.172 5.054 5.054 0 01.217-1.135 4.834 4.834 0 01.4-1.061 5.242 5.242 0 01.454-1.072 5.19 5.19 0 01.782-.872A5.334 5.334 0 012.8.447 4.33 4.33 0 015.1.003z" fill="#86c7c1"/>
</svg>

const BallNavy = () => <svg xmlns="http://www.w3.org/2000/svg" className="ball" viewBox="0 0 10.547 10.615">
  <path d="M5.224.164a5.021 5.021 0 011.142.058c.388.089.914-.165 1.264.005s.555.658.861.9.766.38 1.012.689a5.776 5.776 0 01.5 1.115 5.533 5.533 0 01.51 1.1 5.824 5.824 0 01-.036 1.214 5.155 5.155 0 01-.279 1.142 4.945 4.945 0 01-.349 1.1c-.172.358-.061.918-.305 1.225s-.9.21-1.211.457-.367.888-.723 1.061-.863-.069-1.251.02a5.163 5.163 0 01-1.136.306 5.737 5.737 0 01-1.21.025c-.388-.089-.692-.46-1.042-.63a5.116 5.116 0 01-.983-.624c-.308-.247-.416-.646-.662-.955s-.617-.5-.789-.855A5.287 5.287 0 01.031 6.44a5.623 5.623 0 01.025-1.191A5.29 5.29 0 01.129 4.08c.088-.39-.025-.876.144-1.227s.577-.606.822-.913a5.283 5.283 0 01.9-.766A5.091 5.091 0 013.031.663c.35-.17.621-.556 1.008-.645s.784.146 1.185.146z" fill="#2a4e67"/>
</svg>

const BallDark = () => <svg xmlns="http://www.w3.org/2000/svg" className="ball" viewBox="0 0 10.615 11.28">
  <path d="M5.421.146A4.364 4.364 0 016.534.26c.388.1.86-.128 1.21.058a5.464 5.464 0 011.066.63c.307.27.382.812.628 1.15a5.658 5.658 0 01.687 1.031 6.045 6.045 0 01.443 1.2 6.475 6.475 0 01.029 1.291 5.923 5.923 0 01-.274 1.23c-.088.426.141.965-.028 1.35a5.927 5.927 0 01-.781 1.007c-.245.338-.328.908-.635 1.177a5.652 5.652 0 01-1.1.61c-.35.186-.861-.145-1.248-.048a2.744 2.744 0 01-2.252.186c-.388-.1-.877.085-1.227-.1s-.5-.76-.809-1.029-.891-.263-1.136-.6A11.06 11.06 0 01.037 6.97a7.165 7.165 0 01.016-1.351 6.352 6.352 0 01.343-1.261 6.069 6.069 0 01.326-1.227 72.718 72.718 0 011.448-2 5.14 5.14 0 011-.67 4.848 4.848 0 011.1-.439c.365-.092.749.124 1.151.124z" fill="#001927"/>
</svg>

export const BouncingBalls = () => <div className="bouncing-balls">
  <BallDark />
  <BallRed />
  <BallYellow />
  <BallCyan />
  <BallNavy />
</div>
