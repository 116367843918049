import * as Constants from './constants'

const initialState = {
  readyForRender: false
}

export const reducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case Constants.READY_FOR_RENDER:
      return {
        ...state,
        readyForRender: true
      }
    default:
      return state
  }
}
