import * as Request from '../../request'
import * as Constants from './constants'
import * as Types from './types'

const initialState: Types.State = {
  campaigns: null,
  referralCode: null,
}
export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Request.successConstant(Constants.FETCH_REFERRAL_CAMPAIGNS):
      return {
        ...state,
        campaigns: action.payload.response,
      }
    case Request.requestConstant(Constants.FETCH_REFERRAL_CAMPAIGNS):
      return {
        ...state,
        referralCode: action.options.payload,
      }
    default:
      return state
  }
}
