import * as React from 'react'
import * as UniversalRouter from 'universal-router'
import {NotFoundConnected} from './component'

export const routes = [
  {
    action: (context: UniversalRouter.ActionContext) => <NotFoundConnected />,
    path: `(.*)`,
  },
]
