import * as Request from '../../request'
import * as Common from '../common'
import * as Constants from './constants'
import * as Types from './types'

const emptyState: Types.SurveyState = {
  ...Common.emptyState,
  isLoading: true,
}

export const reducer =
  (state: Types.SurveyState = emptyState, action: Request.SuccessAction)
  : Types.SurveyState => {
  switch (action.type) {
    case Request.successConstant(Constants.FETCH_CONFIGURATION_BY_ICP):
      return {
        ...state,
        ...Common.configurationsState(action.payload.response),
      }
    case Request.successConstant(Constants.FETCH_SURVEY_DATA):
      return {
        ...state,
        isLoading: false,
        url: action.payload.response.survey_url,
      }
    default:
      return state
  }
}
