import isNil from 'lodash/isNil'
import * as React from 'react'
import {connect} from 'react-redux'
import { Col, Container, Row} from 'reactstrap'
import * as Common from '../../common'
import * as Types from '../types'

export const SurveyLanding = ({survey, key, message}: Types.ComponentProps) =>
  <div>
    <Common.PublicHeader configurations={survey.configurations} />
    <Container>
      <Row>
        <Col sm="12">
          <div className="survey-html-content text-center">
            <span dangerouslySetInnerHTML={{
              __html: Common.keyValue(survey.configurations, 'survey_html_content', message),
            }}>
            </span>
          </div>
        </Col>
      </Row>
      {
        survey.isLoading ?
          <Common.BouncingBalls/>
        :
          !isNil(survey.url) ?
            <Row>
              <Col className="text-center">
                Click <a href={survey.url}>here</a> to start the survey.
              </Col>
            </Row>
          :
            <Row>
              <Col className="text-center">
                {Common.keyValue(
                  survey.configurations,
                  'survey_already_completed',
                  'Our records shows that you have already taken the survey. Thank you for taking the survey.',
                )}
              </Col>
            </Row>}
    </Container>
  </div>

export const SurveyLandingConnected = connect(
  ({survey}: Types.WrappedSurveyState) => ({survey}),
  {},
  (stateProps: Types.WrappedSurveyState, _: any, ownProps: Types.ComponentOwnProps) => ({
    ...stateProps,
    ...ownProps,
  })
)(SurveyLanding)
