import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import * as React from 'react'
import * as ReactStrap from 'reactstrap'
import {connect} from 'react-redux'
import {Button, Card, CardBody, Col, Container, Input, Label, Row} from 'reactstrap'
import * as RootUtils from '../../rootUtils'
import * as Common from '../common'
import {HeaderTypes} from '../common'
import * as CommonTypes from '../common/types'
import * as Utils from '../common/utils'
import * as Actions from './actions'
import * as Constants from './constants'
import * as Types from './types'

export const visibleOnDesktop = 'd-none d-lg-block'
export const visibleOnMobile = 'd-block d-lg-none'

const communityName = (topArticles:Types.TopArticlesComponentState): string => {
  const configs = topArticles.configurations.filter((c: CommonTypes.KeyValue) => c.key === 'name')
  return isEmpty(configs) ? topArticles.identifier : configs[0].value
}

const needsHeader = (topArticles: Types.TopArticlesComponentState): boolean => {
  // Show the header unless we are in an iframe OR the configuration (which defaults to true) says to
  return !topArticles.iframe
    && Utils.isTruthy(Utils.keyValue(topArticles.configurations, 'top_articles_header_show'))
}

const needsSignup = (topArticles: Types.TopArticlesComponentState): boolean => {
  // Show the signup unless we are in an iframe OR the configuration (which defaults to true) says to
  return !topArticles.iframe
    && Utils.isTruthy(Utils.keyValue(topArticles.configurations, 'top_articles_signup_show'))
}


interface ArticleProps {
  article: Types.TopArticle,
  configurations: any,
}

interface SignupProps {
  updateField: CommonTypes.UpdateFieldFunction,
  submitSignup: Actions.SubmitSignupActionCreator,
}

interface ReadMoreProps {
  article: Types.TopArticle,
  colClassName: string,
  configurations: any,
  rowClassNamae: string
}
const ReadMoreComponent = ({article, colClassName, configurations, rowClassNamae}: ReadMoreProps) => {
  const readMoreStyles: any = {
    color: Common.keyValue(configurations, 'top_articles_read_more_button_color', ''),
    border: '2px solid ' + Common.keyValue(configurations, 'top_articles_read_more_button_color', ''),
  }
  return <Row className={rowClassNamae}>
          <Col>
            <a className={colClassName} style={readMoreStyles}
               href={article.url+"?utm_source=rasaio&utm_campaign=top-articles&utm_medium=referral&utm_term=read-more"}
               target="_blank" rel="noopener noreferrer">
              {Utils.keyValue(configurations, 'top_articles_read_more_button_text')}
            </a>
          </Col>
        </Row>
}

interface ReadCountProps {
  article: Types.TopArticle,
  countClassName: string,
}
const ReadCountComponent = ({article, countClassName}: ReadCountProps) => {
  const count = article.count || article.unique_count
  if ( count >= 10 ) {
    return <Row>
    <Col className={countClassName}>
      <span>READ {count} TIMES</span>
    </Col>
  </Row>
  } else {
    return null
  }
}
const TermAndConditionChkComponent = ({submitSignup, topArticles, updateField}: Types.WrappedState & SignupProps) => {
  return <Label check>
          <Input type="checkbox"
            className="article-signup-terms-chk"
            checked={Boolean(topArticles.signup.terms_accepted)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateField({key: 'terms_accepted', value: e.target.checked});
              updateField({key: 'terms_and_conditions',
                value: e.target.checked ? topArticles.terms_and_conditions_url : null});
            }} />
          <span>I agree to
            <a href={topArticles.terms_and_conditions_url} target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </a>
          </span>
        </Label>
}
const ThanksComponent = ({topArticles}: Types.WrappedState) => {
  return <div className={'article-signup-thanks ' + topArticles.signup.thanks_class_name}
          hidden={!topArticles.signup.auto_hide}>
          <span style={{color: Utils.keyValue(topArticles.configurations, 'primary_color', '')}}>
            Thanks!
          </span>
        </div>
}
const IssueDateComponent = ({topArticles}: Types.WrappedState) => {
  const issueDate = topArticles.issue_date ||
    (topArticles.articles.length > 0 ? moment(topArticles.articles[0].published).format('dddd, MMM Do YYYY') : '')
  return <div className="article-issue-date">
    {issueDate}
  </div>
}

const HeaderComponent = ({configurations}: any) => {
  return  <ReactStrap.Navbar className="rasa-public-header">
    <ReactStrap.Nav className="center">
      <ReactStrap.NavItem className="center">
        {Utils.keyValue(configurations, 'top_articles_header_image_url') ?
        <img className="brand-header" src={Utils.keyValue(configurations, 'top_articles_header_image_url')}/> :
        <img className="brand-header" src={Utils.keyValue(configurations, 'brand_header_image_url')}/>}
        <div className="brand-header-text"
          dangerouslySetInnerHTML={{__html: Utils.keyValue(configurations, 'top_articles_header_text')}}>
        </div>
      </ReactStrap.NavItem>
    </ReactStrap.Nav>
  </ReactStrap.Navbar>

}

const RenderOnDesktop = ({submitSignup, topArticles, updateField}: Types.WrappedState & SignupProps) => {
  return <div className={`${visibleOnDesktop}`}>
    <Row className="d-header-row">
      <Col lg="3"></Col>
      <Col lg="6"></Col>
      <Col lg="3" className="d-article-issue-date-col">
      { !topArticles.iframe &&
        <IssueDateComponent topArticles={topArticles} />
      }
      </Col>
    </Row>
    <Row>
      <Col>
      { needsHeader(topArticles) &&
        <HeaderComponent configurations={topArticles.configurations} />
      }
      { topArticles.isLoading ?
        <div className="text-center">
          <div className="lds-dual-ring">
          </div>
        </div>
      : null }
      { topArticles.articles.slice(0, 1).map((a: Types.TopArticle) =>
        <DesktopLeadArticleCard configurations={topArticles.configurations} article={a}/>)}
      </Col>
    </Row>
    <Row>
    { topArticles.articles.slice(1,
        parseInt(Utils.keyValue(topArticles.configurations, 'top_articles_max_count'))).map((a: Types.TopArticle) =>
      <DesktopRegularArticleCard configurations={topArticles.configurations} article={a}/>)}
    </Row>
  { needsSignup(topArticles) &&
    <DesktopSignupComponent submitSignup={submitSignup} topArticles={topArticles} updateField={updateField} />
  }
  </div>
}

const DesktopLeadArticleCard = ({article, configurations}: ArticleProps) => {
  return <div className="d-lead-article-rectangle">
    <Row>
      <Col md="9" className="d-lead-article-top-part">
        <Row>
          <Col className="d-lead-article-title">
            <a className="track-url" href={article.url+"?utm_source=rasaio&utm_campaign=top-articles&utm_medium=referral&utm_term=title"}
               target="_blank" rel="noopener noreferrer">
              {article.short_title}
            </a>
          </Col>
        </Row>
        <Row>
          <Col className="d-article-source">{article.site_name}</Col>
        </Row>
        <Row>
          <Col className="d-lead-article-description">
            <span dangerouslySetInnerHTML={{__html: article.short_description}}></span>
          </Col>
        </Row>
        <ReadMoreComponent article={article}
          configurations={configurations}
          rowClassNamae={'d-lead-article-read-more-row'}
          colClassName={'d-lead-article-read-more'} />
      </Col>
      <Col md="3">
        <Row>
          <Col className="d-lead-article-image image">
            <a className="track-url" href={article.url+"?utm_source=rasaio&utm_campaign=top-articles&utm_medium=referral&utm_term=image"}
               target="_blank" rel="noopener noreferrer">
              <img src={RootUtils.getImageUrl(article.hosted_image_url,
                                              Constants.DESKTOP_LEAD_IMAGE_SIZE,
                                              Constants.DESKTOP_LEAD_IMAGE_SIZE)} /></a>
          </Col>
        </Row>
        <ReadCountComponent article={article} countClassName={'d-lead-article-click-count'} />
      </Col>
    </Row>
  </div>
}
const DesktopRegularArticleCard = ({article, configurations}: ArticleProps) => {
  return <Col md="6" >
    <div className="d-regular-article-rectangle">
      <Row className="d-regular-article-top-part">
        <Col md="8">
          <Row>
            <Col className="d-regular-article-title">
              <a className="track-url" href={article.url+"?utm_source=rasaio&utm_campaign=top-articles&utm_medium=referral&utm_term=title"}
                 target="_blank" rel="noopener noreferrer">
                {article.short_title}
              </a>
            </Col>
          </Row>
          <Row>
            <Col className="d-article-source">{article.site_name}</Col>
          </Row>
        </Col>
        <Col  md="4" className="d-regular-article-image image">
        <a className="track-url" href={article.url+"?utm_source=rasaio&utm_campaign=top-articles&utm_medium=referral&utm_term=image"}
           target="_blank" rel="noopener noreferrer">
          <img src={RootUtils.getImageUrl(article.hosted_image_url,
                                          Constants.DESKTOP_REGULAR_IMAGE_SIZE,
                                          Constants.DESKTOP_REGULAR_IMAGE_SIZE)} /> </a>
        </Col>
      </Row>
      <Row>
        <Col className="d-regular-article-description">
          <span dangerouslySetInnerHTML={{__html: article.short_description}}></span>
        </Col>
      </Row>
      <ReadMoreComponent article={article}
        configurations={configurations}
        rowClassNamae={'d-regular-article-read-more-row'}
        colClassName={'d-regular-article-read-more'} />
    </div>
  </Col>
}
const DesktopSignupComponent = ({submitSignup, topArticles, updateField}: Types.WrappedState & SignupProps) => {
  const signupBehaviourConfiguration = Utils.keyValue(topArticles.configurations, 'show_signup_behaviour', '').toLowerCase().trim();
  const signupClassName = signupBehaviourConfiguration == 'animation' ? `article-signup-card show-with-animation ${visibleOnDesktop}`
                                                                      : `article-signup-card ${visibleOnDesktop}`;
  return <Card className={signupClassName}>
    <CardBody hidden={topArticles.signup.auto_hide || topArticles.signup.manual_hide}>
      <Row>
        <Col md="11" className="article-signup-first-line">
          {getSignupText(topArticles.configurations, communityName(topArticles))}
        </Col>
        {signupBehaviourConfiguration == 'animation' ?
          <Col md="1">
          <Button title="Close"
            onClick={() => updateField({key: 'manual_hide', value: true})}
          >X</Button>
        </Col> : <Col md="1"></Col>
        }
      </Row>
      <div className="d-article-signup-email-container">
        <Input
          type="email"
          valid={Utils.validEmail(topArticles.signup.email)}
          invalid={!Utils.validEmail(topArticles.signup.email)}
          placeholder="Email Address (required)"
          onChange={(e) => updateField({key: 'email', value: e.target.value})}
          value={topArticles.signup.email} />
        <Button
          style={{backgroundColor: Utils.keyValue(topArticles.configurations, 'top_articles_signup_button_color', '')}}
          disabled={
            !Utils.validEmail(topArticles.signup.email) ||
            (topArticles.terms_and_conditions_url && !topArticles.signup.terms_accepted)}
          onClick={() => submitSignup(topArticles)}
          className="rasa-submit-btn d-article-signup-btn">
            {Utils.keyValue(topArticles.configurations, 'top_articles_signup_button_text')}
        </Button>
      </div>
      {topArticles.terms_and_conditions_url &&
        <TermAndConditionChkComponent topArticles={topArticles} submitSignup={submitSignup} updateField={updateField} />}
    </CardBody>
    <ThanksComponent topArticles={topArticles} />
  </Card>
}

const getSignupText = (configurations: any, communityName: string) => {
  const signupHTML = Utils.keyValue(configurations, 'top_articles_signup_text').replace('__COMMUNITY_NAME__', communityName)
  return <div dangerouslySetInnerHTML={{__html: signupHTML}}></div>
}

const RenderOnMobile = ({submitSignup, topArticles, updateField}: Types.WrappedState & SignupProps) => {

  return <div className={`${visibleOnMobile}`}>
    <Row className="m-header-row">
      <Col>
      { needsHeader(topArticles) &&
        <Common.PublicHeader configurations={topArticles.configurations} headerType={HeaderTypes.TOP_ARTICLE} />
      }
      </Col>
    </Row>
    <Row>
      <Col>
        <IssueDateComponent topArticles={topArticles} />
      </Col>
    </Row>
  { topArticles.articles.slice(0, 1).map((a: Types.TopArticle) =>
    <Row className="m-lead-article">
      <Col>
        <MobileLeadArticleCard configurations={topArticles.configurations} article={a}/>
      </Col>
    </Row>
  )}
  { topArticles.articles.slice(1, ).map((a: Types.TopArticle) =>
    <Row className="m-regular-article">
      <Col>
        <MobileRegularArticleCard configurations={topArticles.configurations} article={a}/>
      </Col>
    </Row>
    )}
  { needsSignup(topArticles) &&
    <MobileSignupComponent submitSignup={submitSignup} topArticles={topArticles} updateField={updateField} />
  }
  </div>
}
const MobileLeadArticleCard = ({article, configurations}: ArticleProps) => {
  return <div className="m-article-rectangle">
    <Row>
      <Col className="m-article-image image">
        <a className="track-url" href={article.url+"?utm_source=rasaio&utm_campaign=top-articles&utm_medium=referral&utm_term=image"}
          target="_blank" rel="noopener noreferrer">
          <img src={RootUtils.getImageUrl(article.hosted_image_url,
                                          Constants.MOBILE_LEAD_IMAGE_HEIGHT,
                                          Constants.MOBILE_IMAGE_WIDTH)} />
        </a>
      </Col>
    </Row>
    <ReadCountComponent article={article} countClassName={'m-article-click-count'} />
    <Row>
      <Col className="m-article-title">
        <a className="track-url" href={article.url+"?utm_source=rasaio&utm_campaign=top-articles&utm_medium=referral&utm_term=title"}
           target="_blank" rel="noopener noreferrer">
         {article.short_title}
        </a>
      </Col>
    </Row>
    <Row>
      <Col className="m-article-source">{article.site_name}</Col>
    </Row>
    <Row>
      <Col className="m-article-description">
        <span dangerouslySetInnerHTML={{__html: article.short_description}}></span>
      </Col>
    </Row>
    <ReadMoreComponent article={article}
      configurations={configurations}
      rowClassNamae={'m-lead-article-read-more-row'}
      colClassName={'m-lead-article-read-more'} />
  </div>
}
const MobileRegularArticleCard = ({article, configurations}: ArticleProps) => {
  return <Col>
    <div className="m-article-rectangle">
      <Row>
        <Col className="m-article-image image">
          <a className="track-url" href={article.url+"?utm_source=rasaio&utm_campaign=top-articles&utm_medium=referral&utm_term=image"}
             target="_blank" rel="noopener noreferrer">
            <img src={RootUtils.getImageUrl(article.hosted_image_url,
                                           Constants.MOBILE_REGULAR_IMAGE_HEIGHT,
                                           Constants.MOBILE_IMAGE_WIDTH)} />
          </a>
        </Col>
      </Row>
      <Row>
        <Col className="m-article-title" >
          <a className="track-url" href={article.url+"?utm_source=rasaio&utm_campaign=top-articles&utm_medium=referral&utm_term=title"}
            target="_blank" rel="noopener noreferrer">
            {article.short_title}
          </a>
        </Col>
      </Row>
      <Row>
        <Col className="m-article-source">{article.site_name}</Col>
      </Row>
      <Row>
        <Col className="m-article-description">
          <span dangerouslySetInnerHTML={{__html: article.short_description}}></span>
        </Col>
      </Row>
      <ReadMoreComponent article={article}
        configurations={configurations}
        rowClassNamae={'m-regular-article-read-more-row'}
        colClassName={'m-regular-article-read-more'} />
    </div>
  </Col>
}
const MobileSignupComponent = ({submitSignup, topArticles, updateField}: Types.WrappedState & SignupProps) => {
  return <Card className="article-signup-card show-with-animation" >
    <CardBody hidden={topArticles.signup.auto_hide || topArticles.signup.manual_hide}>
      <Row>
        <Col className="article-signup-first-line">
          {getSignupText(topArticles.configurations, communityName(topArticles))}
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            type="email"
            className="m-article-signup-email"
            valid={Utils.validEmail(topArticles.signup.email)}
            invalid={!Utils.validEmail(topArticles.signup.email)}
            placeholder="Email Address (required)"
            onChange={(e) => updateField({key: 'email', value: e.target.value})}
            value={topArticles.signup.email} />
        </Col>
      </Row>
      {topArticles.terms_and_conditions_url &&
      <Row>
        <Col>
          <TermAndConditionChkComponent topArticles={topArticles} submitSignup={submitSignup} updateField={updateField} />
        </Col>
      </Row>}
      <div className="m-article-signup-btn-container">
        <Button
          style={{backgroundColor: Utils.keyValue(topArticles.configurations, 'top_articles_signup_button_color', '')}}
          disabled={
            !Utils.validEmail(topArticles.signup.email) ||
            (topArticles.terms_and_conditions_url && !topArticles.signup.terms_accepted)}
          onClick={() => submitSignup(topArticles)}
          className="rasa-submit-btn m-article-signup-btn">
            {Utils.keyValue(topArticles.configurations, 'top_articles_signup_button_text')}
        </Button>
        <Button title="Close" className="m-article-signup-btn"
          onClick={() => updateField({key: 'manual_hide', value: true})}>
          Cancel
        </Button>
      </div>
    </CardBody>
    <ThanksComponent topArticles={topArticles} />
  </Card>
}

const TopArticles = ({submitSignup, topArticles, updateField}: Types.WrappedState & SignupProps) =>
<div>
  <Container>
    <RenderOnMobile topArticles={topArticles} submitSignup={submitSignup} updateField={updateField}/>
    <RenderOnDesktop topArticles={topArticles} submitSignup={submitSignup} updateField={updateField}/>
  </Container>
</div>

export const TopArticlesConnected = connect(
  ({topArticles}: Types.WrappedState) => ({topArticles}),
  {
    submitSignup: Actions.submitSignup,
    updateField: Actions.updateSignupField,
  }
)(TopArticles)
