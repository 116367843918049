import * as Request from '../../request'
import * as RootUtils from '../../rootUtils'
import * as CommonConstants from '../common/constants'
import * as CommonTypes from '../common/types'
import * as Utils from '../common/utils'
import * as Constants from './constants'
import * as Types from './types'
import { isEmpty } from 'lodash'

const emptyState: Types.TopArticlesComponentState = {
  ...Utils.emptyState,
  articles: [],
  issue_date: '',
  signup: {
    email: '',
    auto_hide: false,
    manual_hide: false,
    terms_accepted: false,
    terms_and_conditions: null,
    utm_campaign: '',
    utm_source: Constants.DEFAULT_SOURCE,
  },
  terms_and_conditions_url: null,
  isLoading: true,
}

const normalizeArticle = (payload: Types.TopArticle): Types.TopArticle => {
  return {
    ...payload,
    short_description: RootUtils.shortText(payload.description, Constants.SHORT_DESCRIPTION_LENGTH),
    short_title: RootUtils.shortText(payload.title, Constants.SHORT_TITLE_LENGTH),
  }
}

type Action = Request.SuccessAction & CommonTypes.WrappedUpdateField & Types.InitialData

const keepArticle = (a: Types.TopArticle): boolean => {
  return a.post_id !== null && !isEmpty(a.image_url) && !isEmpty(a.description)
}

const getTermsAndConditions = (configurations: CommonTypes.KeyValue[]): string => {
  const configs = configurations.filter((c: CommonTypes.KeyValue) => c.key === 'terms_and_conditions_url')
  if ( configs && configs.length > 0) {
    return configs[0].value
  } else {
    return null
  }
}

export const reducer =
  (state: Types.TopArticlesComponentState = emptyState, action: Action) => {
  switch (action.type) {
    case Constants.TOP_ARTICLES_INITIAL_DATA:
      return {
        ...state,
        iframe: action.params.iframe,
        signup: {
          ...state.signup,
          utm_campaign: action.params.issue_identifier
        },
      }
    case Request.successConstant(Constants.FETCH_TOP_ARTICLES):
      return {
        ...state,
        articles: (action.payload.response.results || action.payload.response.data).filter((a: Types.TopArticle) => keepArticle(a)).map((r: any) => normalizeArticle(r)),
        issue_date: action.payload.response.issue_date ?
          new Date(action.payload.response.issue_date).toLocaleDateString(Constants.DATE_LOCALE, Constants.DATE_OPTIONS) : null,
        isLoading: false
      }
    case Request.successConstant(CommonConstants.FETCH_CONFIGURATION):
      return {
        ...state,
        ...Utils.initializedState(action.payload.response),
        terms_and_conditions_url: getTermsAndConditions(action.payload.response),
      }
    case Constants.TOP_ARTICLES_UPDATE_SIGNUP_FIELD:
      return {
        ...state,
        signup: {
          ...state.signup,
          ...{[action.field.key]: action.field.value},
        },
      }
    case Request.successConstant(Constants.TOP_ARTICLES_SUBMIT_SIGNUP):
      return {
        ...state,
        signup: {
          ...state.signup,
          auto_hide: true,
          thanks_class_name: 'hide-with-animation',
        },
      }
    default:
      return state
  }
}
