import queryString from 'query-string'
import * as ReduxObservable from 'redux-observable'
import { delay, filter, mergeMap, map, tap } from 'rxjs/operators'
import * as Router from '../../router'
import * as Request from '../../request'
import * as Common from '../common'
import * as Actions from './actions'
import * as Constants from './constants'

const onEnter: ReduxObservable.Epic = (action$, state$, {history}) =>
  action$.ofType(Router.ON_ENTER)
  .pipe(
    filter(() => history.location.pathname.startsWith(Constants.URL_HOME)),
    map((args) => ({
      ...args,
      query: queryString.parse(history.location.search)
    })),
    mergeMap((({ params, query }: Common.WrappedParams) => [
      Common.setGuid(query.guid as string),
      Common.fetchAllSchedules(params.community_identifier),
      Common.fetchMemberSchedule(params.community_identifier, query.guid),
    ]))
  )

const onEnterAutoUnsubscribe: ReduxObservable.Epic = (action$, state$, {history}) =>
  action$.ofType(Router.ON_ENTER)
  .pipe(
    filter(() => history.location.pathname.startsWith(Constants.URL_HOME)),
    filter(() => history.location.pathname.includes(`/${Constants.AUTO_UNSUBSCRIBE}`)),
    map(() => Actions.unsubscribe(queryString.parse(history.location.search).guid as string, false, 'auto-unsubscribe'))
  )

const fakeResponse = (requestEvent: string, fakeEvent: string): ReduxObservable.Epic =>
  (action$, state$, {history}) =>
  action$.ofType(Request.requestConstant(requestEvent))
  .pipe(
    delay(1000),
    map(() => ({type: fakeEvent}))
  )

export const epic = ReduxObservable.combineEpics(
  fakeResponse(Constants.SUBMIT_SUBSCRIBE, Constants.FAKE_SUBMIT_SUBSCRIBE_SUCCESS),
  fakeResponse(Constants.SUBMIT_UNSUBSCRIBE, Constants.FAKE_SUBMIT_UNSUBSCRIBE_SUCCESS),
  onEnter,
  onEnterAutoUnsubscribe,
)
