import * as CommonTypes from '../common/types'
import * as Utils from '../common/utils'

export type SignupCampaign = string
export type SignupSource = string
export type ReferredBy = string

export interface SignupState {
  utm_campaign?: SignupCampaign,
  email: string,
  first_name: string,
  iframe?: boolean,
  last_name: string,
  referred_by?: ReferredBy,
  utm_source?: SignupSource
  recaptcha?: string,
}

export interface SignupComponentState extends CommonTypes.SubmittableCommunityState {
  signup: SignupState,
}

export const validSignup = (signup: SignupState): boolean => {
  return Utils.validEmail(signup.email)
}

export interface SignupSourceData {
  iframe: boolean,
  referred_by: ReferredBy,
  utm_campaign: SignupCampaign,
  utm_source: SignupSource,
}

export interface SignupSourceState {
  source: SignupSourceData
}
