import isEmpty from 'lodash/isEmpty'
import * as Common from '../pages/common'
import * as Types from './types'

const getOgKey = (prefix: string, type: string): string => `open_graph_${prefix}_${type}`

const getValue = (configs: Common.KeyValue[], key: string): string => {
  const values = configs.filter((kv: Common.KeyValue) => kv.key === key)
  if (isEmpty(values) || isEmpty(values[0].value)) {
    return null
  } else {
    return values[0].value
  }
}

export const ogTags = (configs: Common.KeyValue[], prefix: string): Types.OpenGraphTag => {
  return {
    description: getValue(configs, getOgKey(prefix, 'description')) || getValue(configs, 'signup_lead_text'),
    image: getValue(configs, getOgKey(prefix, 'image_url')) || getValue(configs, 'brand_header_image_url'),
    siteName: getValue(configs, getOgKey(prefix, 'site_name')) || `${getValue(configs, 'name')} Newsletter, powered by rasa.io`,
    title: getValue(configs, getOgKey(prefix, 'title')) || `${getValue(configs, 'name')} Newsletter, powered by rasa.io`,
    twitterHandle: getValue(configs, 'twitter_handle'),
    favicon: getValue(configs, 'favicon_url'),
    faviconLarge: getValue(configs, 'favicon_url_large')
  }
}
