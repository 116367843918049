import * as RootConstants from './rootConstants'

export const cleanText = (text: string): string => {
  if (text != null) {
    return text.replace(/(^\s*)|(\s*$)/gi, '') /*exclude  start and end white-space */
    .replace(/[ ]{2,}/gi, ' ') /* 2 or more space to 1 */
    .replace(/\n /, '\n') /* newlines */
  } else {
    return text
  }
}

export const shortText = (text: string, length: number): string => {
  if ( text == null ) {
    return ''
  } else {
    const ct = cleanText(text)
    if (ct.length < length) {
      return ct
    } else {
      const shorter = ct.substring(0, length)
      const words = shorter.split(' ')
      return words.slice(0, words.length - 1).join(' ') + '...'
    }
  }
}

export const getImageUrl = (hostedImageUrl: string, height: number, width: number) => {
  if (hostedImageUrl != null){
    const temp = hostedImageUrl.replace(RootConstants.HOSTED_IMAGE_SERVER_RESOURCE, RootConstants.IMAGE_SERVER_RESOURCE)
    return temp + '?fit=crop&crop=entropy&h=' + height + '&w=' + width + '&auto=enhance';
  }
  else {
    return '';
  }
}
