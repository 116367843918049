import * as React from 'react'
import {connect} from 'react-redux'
import * as ReactStrap from 'reactstrap'
import * as Redux from 'redux'
import * as Common from '../common'
import * as CommonTypes from '../common/types'
import * as Utils from '../common/utils'
import * as Actions from './actions'
import * as Types from './types'

type UpdateArticleFeedbackFieldFunction = (articleField: Types.ArticleFeedbackField) => Redux.Action

interface RootDispatchProps {
  submit: Actions.SubmitNewsbriefFeedbackActionCreator,
  updateField: CommonTypes.UpdateFieldFunction,
  updateArticleField: UpdateArticleFeedbackFieldFunction
}

export interface WrappedFeedbackState {
  feedback: Types.FeedbackState
}

export const Newsbrief =
  ({feedback, submit, updateField, updateArticleField}: RootDispatchProps & WrappedFeedbackState) =>
<ReactStrap.Container className="feedback-root">
    <ReactStrap.Row>
      <ReactStrap.Col sm="12">
        <Common.PublicHeader configurations={feedback.configurations}/>
      </ReactStrap.Col>
    </ReactStrap.Row>
    <ReactStrap.Container className="feedback-container">
    <ReactStrap.Row>
      <ReactStrap.Col sm="12">
        <div className="lead-text">
          <span dangerouslySetInnerHTML={{
            __html: Utils.keyValue(feedback.configurations, 'feedback_lead_text', ''),
          }}>
          </span>
        </div>
      </ReactStrap.Col>
    </ReactStrap.Row>
    </ReactStrap.Container>

  {feedback.submitted ?
    <ReactStrap.Container>
      <h1 className="thank-you">Thank you for your feedback.</h1>
    </ReactStrap.Container>
  : !feedback.newsbrief.token || feedback.is_submitting ?
    <Common.BouncingBalls />
  :
    <ReactStrap.Row>
      <NewsbriefFeedback feedback={feedback}
                         updateField={updateField}
                         updateArticleField={updateArticleField}
                         submit={submit}/>
    </ReactStrap.Row>}
</ReactStrap.Container>

const NewsbriefFeedback =
  ({feedback, submit, updateField, updateArticleField}: RootDispatchProps & WrappedFeedbackState) =>
<ReactStrap.Container className="feedback-container">
  <ReactStrap.Row>
    <ReactStrap.Col>
      <h2>How relevant was each article for you?</h2>
      <hr/>
    </ReactStrap.Col>
  </ReactStrap.Row>
  <ReactStrap.Row>
  {feedback.newsbrief.articles.filter((article) => article.post_type !== 'Promotion').map((article) =>
    <ReactStrap.Col xs="12" key={article.message_run_detail_id}>
      <NewsbriefFeedbackArticle article={article} updateArticleField={updateArticleField} />
    </ReactStrap.Col>)}
  </ReactStrap.Row>
  <ReactStrap.Row>
    <ReactStrap.Col lg="5">
      <NewsbriefComment field="comments"
                        label="Comments on this newsletter?"
                        updateField={updateField}
                        value={feedback.newsbrief.comments} />
    </ReactStrap.Col>
    <ReactStrap.Col lg="5">
      <NewsbriefComment field="suggested_sources"
                        label="Additional source suggestions?"
                        placeholder="(i.e. www.nytimes.com)"
                        updateField={updateField}
                        value={feedback.newsbrief.suggested_sources} />
    </ReactStrap.Col>
    <ReactStrap.Col xs="12" sm="12" lg="2" className="text-center extra-padding--top-10">
        <ReactStrap.Button
          disabled={!Types.validFeedback(feedback.newsbrief)}
          style={{backgroundColor: Utils.keyValue(feedback.configurations, 'primary_color', '')}}
          onClick={() => submit(feedback.newsbrief, feedback.identifier)}
          className="rasa-submit-btn"
          size="lg">
          Submit Feedback
        </ReactStrap.Button>
    </ReactStrap.Col>
  </ReactStrap.Row>
</ReactStrap.Container>

export interface WrappedNewsbriefCommentState {
  field: string,
  label: string,
  placeholder?: string,
  updateField: CommonTypes.UpdateFieldFunction,
  value: string,
}

const NewsbriefComment = ({field, label, updateField, value, placeholder}: WrappedNewsbriefCommentState) =>
<ReactStrap.Card key={field}>
  <ReactStrap.CardHeader>
    <ReactStrap.CardTitle>{label}</ReactStrap.CardTitle>
  </ReactStrap.CardHeader>
  <ReactStrap.CardBody>
    <ReactStrap.Input
        type="textarea"
        placeholder={placeholder}
        valid={Utils.validStringInput(value)}
        onChange={(e) => updateField({key: field, value: e.target.value})}
        value={value}/>
  </ReactStrap.CardBody>
</ReactStrap.Card>

export interface WrappedNewsbriefArticleComponentState {
  article: Types.NewsbriefArticleFeedbackState,
  updateArticleField: UpdateArticleFeedbackFieldFunction,
}

const NewsbriefFeedbackArticle = ({article, updateArticleField}: WrappedNewsbriefArticleComponentState) =>
<ReactStrap.Row key={article.message_run_detail_id}
  className="newsbrief-feedback-article-row">
  <ReactStrap.Col sm="12" md="3" className="text-md-left">
    <span className="small-title">{article.source}</span>
  </ReactStrap.Col>
  <ReactStrap.Col sm="12" md="7" className="text-md-left">
    <a href={article.post_url} target="_blank" rel="noopener noreferrer">{article.title}</a>
  </ReactStrap.Col>
  <ReactStrap.Col sm="12" md="2" className="article-ranking">
    <ReactStrap.FormGroup check>
      <ReactStrap.ButtonGroup>
        <ReactStrap.Button
            className="thumbs-up"
            color="rating"
            onClick={() => updateArticleField(
              {article_id: article.message_run_detail_id, key: 'rating', value: '1'})
            }
            active={article.rating === '1'}>
          <i className="fas fa-thumbs-up"/>
        </ReactStrap.Button>
        &nbsp;
        <ReactStrap.Button
            className="thumbs-down"
            color="rating"
            onClick={() => updateArticleField(
              {article_id: article.message_run_detail_id, key: 'rating', value: '-1'})
            }
            active={article.rating === '-1'}>
          <i className="fas fa-thumbs-down"/>
        </ReactStrap.Button>
      </ReactStrap.ButtonGroup>
    </ReactStrap.FormGroup>
  </ReactStrap.Col>
</ReactStrap.Row>

export interface WrappedReducerFeedbackState {
  feedback: Types.FeedbackState
}

export const NewsbriefFeedbackConnected = connect(
  ({feedback}: WrappedReducerFeedbackState) => ({feedback}),
  {
    submit: Actions.submitFeedback,
    updateArticleField: Actions.updateArticleFeedbackField,
    updateField: Actions.updateFeedbackField,
  },
)(Newsbrief)
