import find from 'lodash/find'
import * as Request from '../../request'
import * as CommonTypes from '../common/types'
import * as Utils from '../common/utils'
import * as Constants from './constants'
import * as Types from './types'

type FeedbackAction = Request.SuccessAction & CommonTypes.WrappedUpdateField & Types.WrappedArticleFeedbackField

const emptyState: Types.FeedbackState = {
  ...Utils.emptyState,
  newsbrief: {
    articles: [],
    token: null,
  },
}

export const reducer = (state: Types.FeedbackState = emptyState, action: FeedbackAction) => {
  switch (action.type) {
    case Request.successConstant(Constants.FETCH_NEWSBRIEF):
      return {
        ...Utils.initializedState(action.payload.response.configuration),
        newsbrief: action.payload.response.feedback,
      }
    case Constants.UPDATE_FEEDBACK_FIELD:
      return {
          ...state,
          newsbrief: {
            ...state.newsbrief,
            ...{[action.field.key]: action.field.value},
          },
        }
    case Constants.UPDATE_ARTICLE_FEEDBACK_FIELD:
      const newState = {
        ...state,
      }
      const a = find(newState.newsbrief.articles,
        (article: Types.NewsbriefArticleFeedbackState) =>
          article.message_run_detail_id === action.articleField.article_id)
      // Setting to the same value -- this means we clicked again on the clicked
      // button.  In that case, reset.
      a[action.articleField.key] =
        action.articleField.value === a[action.articleField.key] ? '0' : action.articleField.value
      return newState
    case Request.requestConstant(Constants.SUBMIT_NEWSBRIEF_FEEDBACK):
      return Utils.submittedState(state)
    case Request.successConstant(Constants.SUBMIT_NEWSBRIEF_FEEDBACK):
    case Request.errorConstant(Constants.SUBMIT_NEWSBRIEF_FEEDBACK):
      return Utils.returnedState(state)
    default:
      return state
  }
}
